import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import history from "../history";
import React, {useCallback, useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import {useDropzone} from "react-dropzone";
import {FaChevronLeft} from "react-icons/fa";
import util from "../util/util";
import OrderDao from "../daos/OrderDao";
import BankAccountDao from "../daos/BankAccountDao";
import UploadDao from "../daos/UploadDao";
import Spinner from "react-bootstrap/Spinner";
import {GrPowerCycle} from "react-icons/gr";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import Button from "@material-ui/core/Button";

export default function PaymentSection(props) {
    const {id, show} = props;
    const [file, setFile] = useState(null);
    const [order, setOrder] = useState({});
    const [bankAcc, setBankAcc] = useState({});
    const [isUploading, setUploading] = useState(false);
    const [isError, setError] = useState(false);
    const [isReceiptHovered, setReceiptHovered] = useState(false);
    const [receiptUrl, setReceiptUrl] = useState(false);

    const [showImage, setShowImage] = useState(false)

    const uploadPaymentReceipt = () => {
        setUploading(true)
        setError(false)
        const formData = new FormData();

        formData.append('upload', file);

        UploadDao.uploadImage('PAYMENT_RECEIPT', formData).then(res => {
            setUploading(false)


            OrderDao.changeReceipt(id, {
                receipt_image_url: res.location
            }).then(res => {
                console.log("HOI ", res)
                if (res.success) alert('Receipt has been uploaded successfully! Please wait while your order is being processed.')
            }).catch(err => {
                console.log(err)
                alert('An error occured')
            })
            // console.log(res)
            setReceiptUrl(res.location);

        }).catch(err => {
            setError(true)
            setUploading(false)
            alert('An error occurred')
        })
    }

    useEffect(() => {
        if (show) {
            if (Object.keys(order).length === 0) {
                OrderDao.checkIfUserAuthorized(id).then(res => {
                    console.log('order', res)

                    if (res.receipt_image_url && ORDER_STEPS[res.current_order_step] !== ORDER_STEPS.PAYMENT_REJECTED) {
                        setReceiptUrl(res.receipt_image_url);
                        setFile({name: `${res.order_number}_RECEIPT`});
                    }

                    setOrder(res)
                }).catch(err => {
                    console.log(err)
                    if (err.http_status) {
                        if (err.http_status === 403) {
                            alert('You aren\'t authorized to continue this payment. Please log in using an authorized account.');

                            localStorage.clear();
                            sessionStorage.clear();
                            history.push('/', {redirect: `confirm-payment/${id}`});
                        } else if (err.http_status) {
                            history.push('/orders')
                        }
                    }
                })

                // OrderDao.getById(id).then(res => {
                //     if(res.receipt_image_url) setFile({name: `${res.order_number}_RECEIPT`});
                //     console.log(res)
                //     setOrder(res)
                // }).catch(err => console.log(err))
            }

            if (Object.keys(bankAcc).length === 0) {
                BankAccountDao.getInfo().then(res => {
                    setBankAcc(res)
                }).catch(err => console.log(err))
            }

            if (file && file instanceof File) {
                uploadPaymentReceipt()
            }
        }
    }, [file, show])

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)

        if (acceptedFiles[0].type !== 'image/png' && acceptedFiles[0].type !== 'image/jpeg' && acceptedFiles[0].type !== 'application/pdf') {
            alert('Only PNG/JPEG/PDF format is accepted');
        } else {
            setFile(acceptedFiles[0])
        }

    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        disabled: false
    })

    function getExtension(path) {
        const basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
            // (supports `\\` and `/` separators)
            pos = basename.lastIndexOf(".");       // get last position of `.`

        if (basename === "" || pos < 1)            // if file name is empty or ...
            return "";                             //  `.` not found (-1) or comes first (0)

        return basename.slice(pos + 1);            // extract extension ignoring `.`
    }

    return (
        <Container
            style={{display: show ? 'flex' : 'none', justifyContent: 'center', paddingBottom: 50, marginTop: 60}}>
            <Row style={{
                backgroundColor: '#f5f5f5',
                boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                width: '100%',
                maxWidth: 500,
                borderRadius: 15,
                marginTop: 60,
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 40,
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 50,
            }}>
                <Row>
                    <Col xs={1} style={{paddingLeft: 0}}>
                        <FaChevronLeft style={{opacity: 0}}/>
                    </Col>

                    <Col style={{
                        fontWeight: '400',
                        fontSize: '1.1em',
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'center',
                        color: '#594f4f'
                    }}>
                        Please send <span
                        style={{fontWeight: '600'}}>{order.source_currency} {order.source_amount ? util.thousandSeparator(order.source_amount) : 0}</span><br/>to
                        this bank
                        account:
                    </Col>
                    <Col xs={1}/>
                </Row>

                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: Palette.COLOR_PRIMARY,
                    fontWeight: '600',
                    fontSize: '2em',
                    marginTop: 5,
                    marginBottom: 15
                }}>
                    {bankAcc.bank_account_number}
                </Row>

                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#594f4f',
                    fontWeight: '600',
                    fontSize: '1.25em',
                    marginTop: 5,
                    textAlign: 'center'
                }}>
                    {bankAcc.account_name}<br/>
                    {bankAcc.bank_name}
                </Row>

                <Row style={{height: 1, backgroundColor: '#594f4f', marginTop: 30, marginBottom: 40}}/>

                {!file ?
                    <>
                        <Row style={{marginBottom: 20}}>
                            <Col xs={1} style={{paddingLeft: 0}}/>

                            <Col style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                color: '#594f4f'
                            }}>
                                Upload Receipt
                            </Col>
                            <Col xs={1}/>
                        </Row>

                        <Row>
                            <Col xs={1} style={{paddingLeft: 0}}/>

                            <Col
                                {...getRootProps()}
                                style={{
                                    boxShadow: isDragActive ? `0 0 10px ${Palette.COLOR_PRIMARY}` : null,
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    color: Palette.COLOR_PRIMARY,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    fontWeight: '600',
                                    paddingTop: 12,
                                    paddingBottom: 12,
                                    border: `2px dashed ${Palette.COLOR_PRIMARY}`,
                                    borderRadius: 5,
                                    cursor: 'pointer'
                                }}>
                                <input {...getInputProps()} />
                                Drag Receipt Image Here
                            </Col>
                            <Col xs={1}/>
                        </Row>

                        <Row style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#594f4f',
                            fontSize: '.9em',
                            textAlign: 'center',
                            marginTop: 10,
                            marginBottom: 15
                        }}>
                            or
                        </Row>

                        <Row style={{
                            display: 'flex',
                            // alignSelf: 'flex-start',
                            color: Palette.COLOR_PRIMARY,
                            marginTop: 10,
                            marginBottom: 15,
                            justifyContent: 'flex-start',

                        }}
                        >
                            <Col xs={1} style={{paddingLeft: 0}}/>

                            <Col style={{
                                paddingLeft: 0,
                                paddingRight: 0,
                                display: 'flex'
                            }}>
                                <div style={{
                                    alignSelf: 'flex-start',
                                    borderBottom: `2px solid ${Palette.COLOR_PRIMARY}`,
                                    cursor: 'pointer'
                                }}  {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    Choose from files...
                                </div>
                            </Col>
                            <Col xs={1}/>
                        </Row>
                    </> :
                    <Row style={{
                        display: 'flex',
                        // alignSelf: 'flex-start',
                        color: Palette.COLOR_PRIMARY,
                        marginTop: 10,
                        marginBottom: 15,
                        justifyContent: 'flex-start',

                    }}
                    >
                        <Col xs={1} style={{paddingLeft: 0}}/>

                        <Col style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            backgroundColor: '#dce1e5',
                            alignItems: 'center',
                            color: '#3f9e59',
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderRadius: 50,
                            paddingLeft: 30,
                            paddingRight: 20
                        }}>
                            <div style={{
                                flex: 1,
                                textDecorationLine: !isUploading && isReceiptHovered ? 'underline' : 'none',
                                cursor: !isUploading && isReceiptHovered ? 'pointer' : 'none'
                            }}
                                 onClick={() => {
                                     // setShowImage(!showImage)
                                     if(receiptUrl) window.open(receiptUrl, '_blank')
                                 }}
                                 onMouseOver={() => setReceiptHovered(true)}
                                 onMouseLeave={() => setReceiptHovered(false)}>
                                {isUploading ? 'Uploading . . .' : file?.name?.length > 15 ? file?.name?.substr(0,14)+"..." : file.name}
                            </div>

                            {isUploading ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : isError ?
                                    <GrPowerCycle style={{cursor: 'pointer'}} onClick={uploadPaymentReceipt}/> :
                                    ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_PAYMENT || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.PAYMENT_REJECTED || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_APPROVAL ?
                                        <>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <Button style={{color: Palette.COLOR_PRIMARY, fontSize: 16}}>Edit</Button>
                                            </div>
                                            {
                                                receiptUrl && getExtension(receiptUrl) !== 'pdf' ?
                                                    <Button
                                                        onClick={() => {
                                                            setShowImage(!showImage)
                                                            console.log(file)
                                                            // if(receiptUrl) window.open(receiptUrl, '_blank')
                                                        }}
                                                        style={{color: Palette.COLOR_PRIMARY, fontSize: 16}}>{showImage ? "Hide" : "Show"}</Button>
                                                    :
                                                    null
                                            }

                                        </> : null
                                // <AiOutlineClose style={{cursor: 'pointer'}} onClick={() => setFile(null)}/>
                            }
                        </Col>
                        <Col xs={1}/>
                    </Row>
                }

                {
                    file && showImage?
                        <Row>
                            <img
                                style={{width : "100%"}}
                                src={receiptUrl}/>
                        </Row>
                        :
                        null
                }

                {/*{file ?*/}
                {/*    <Row style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*        <Button style={{...styles.button, marginTop: 10, marginBottom: 10}} onClick={onNext}>*/}
                {/*            Next*/}
                {/*        </Button>*/}
                {/*    </Row> : null*/}
                {/*}*/}

                <Row style={{fontSize: '.9em', textDecorationLine: 'underline', marginTop: 25}}>
                    <Col xs={1} style={{paddingLeft: 0}}/>
                    <Col style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: 'flex',
                        width: '100%',
                        justifyContent: file ? 'center' : 'flex-end'
                    }}>
                        <a href={'/orders'} style={{textDecorationLine: 'none'}}>
                            <div style={{cursor: 'pointer', color: 'grey'}}>
                                Go to home
                            </div>
                        </a>
                    </Col>
                    <Col xs={1}/>
                </Row>
            </Row>
        </Container>
    )
}
