import AppContainer from "../reusables/AppContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import React, {useEffect, useState} from "react";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import DefaultFilePicker from "../reusables/DefaultFilePicker";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import moment from "moment";
import CustomerDao from "../daos/CustomerDao";
import history from "../history";
import UploadDao from "../daos/UploadDao";

export default function FinancialInstitutionDetailPage(props) {
    const isUpdate = props.match.params && props.match.params.action === 'update';
    const [isChecked, setChecked] = useState(false); //indicate whether user has accepted term and condition

    let user = {};

    if (isUpdate) {
        user = props.location.state.user;
    }

    const [name, setName] = useState(user.primary_name);
    const [owner, setOwner] = useState(user.secondary_name);
    const [address, setAddress] = useState(user.address);
    const [corpPlace, setCorpPlace] = useState(user.place_of_incorporation);
    const [corpDate, setCorpDate] = useState(Object.keys(user).length !== 0 ? moment(user.date_of_incorporation).format('YYYY-MM-DD') : null);
    const [nibNo, setNibNo] = useState(user.id_number);
    const [phoneNo, setPhoneNo] = useState(user.phone_number);
    const [contactPerson, setContactPerson] = useState(user.contact_person);
    const [npwpNo, setNpwpNo] = useState(user.npwp_number);
    const [businessNature, setBusinessNature] = useState(user.business_nature);
    const [corpCountry, setCorpCountry] = useState(user.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(user.country_of_incorporation) ? 'Other' : user.country_of_incorporation);
    const [documentImageUrl, setDocumentImageUrl] = useState(user.id_document_image_url);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [errors, setErrors] = useState({})
    const [file, setFile] = useState(null);
    const [isUploading, setUploading] = useState(false);
    const [otherCorpCountryValue, setOtherCorpCountryValue] = useState(user.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(user.country_of_incorporation) ? user.country_of_incorporation : null);

    useEffect(() => {
        if (file) {
            setUploading(true)
            uploadNIB()
        }
    }, [file])

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptSubmit()
        }
    }

    const uploadNIB = () => {
        const formData = new FormData();

        formData.append('upload', file);

        UploadDao.uploadImage('FINANCIAL_ID', formData).then(res => {
            setUploading(false);
            setDocumentImageUrl(res.location);
        }).catch(err => {
            const errorsTemp = {...errors};
            errorsTemp["UPLOAD NIB / BUSINESS LICENSE"] = 'An error occured';
            setErrors(errorsTemp);
            console.log(err)
        })
    }

    const attemptSubmit = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);
        setErrorMsg(false);
        setSuccessMsg(false)

        if (!name) errorsTemp["FINANCIAL INSTITUTION NAME"] = 'Financial institution name cannot be blank';
        if (!owner) errorsTemp["OWNER NAME"] = 'Owner name cannot be blank';
        if (!address) errorsTemp["ADDRESS"] = 'Address cannot be blank';
        if (!corpPlace) errorsTemp["CITY OF INCORPORATION"] = 'City of incorporation cannot be blank';
        if(!moment(corpDate).isValid() || moment(corpDate).isAfter(moment(new Date()))) errorsTemp["DATE OF INCORPORATION"] = `Please input a valid date prior to today`;
        if (!corpDate) errorsTemp["DATE OF INCORPORATION"] = 'Date of incorporation hasn\'t set';
        if (!nibNo) errorsTemp["NO. NIB"] = 'No. NIB cannot be blank';
        if (!phoneNo) errorsTemp["PHONE NUMBER"] = 'Phone number cannot be blank';
        if (!contactPerson) errorsTemp["RUNNER / CONTACT PERSON"] = 'Runner / contact person cannot be blank';
        if (!npwpNo) errorsTemp["NO. NPWP"] = 'No. NPWP cannot be blank';
        if (!businessNature) errorsTemp["BUSINESS NATURE"] = 'Business nature cannot be blank';
        if (!corpCountry) errorsTemp["COUNTRY OF INCORPORATION"] = 'Country of incorporation cannot be blank';
        if(corpCountry === 'Other' && !otherCorpCountryValue) errorsTemp["COUNTRY OF INCORPORATION"] = 'Country of incorporation cannot be blank';
        if (!documentImageUrl) errorsTemp["UPLOAD NIB / BUSINESS LICENSE"] = 'NIB / business license hasn`t uploaded';
        if (Object.keys(errorsTemp).length === 0) {
            if (!isChecked && !isUpdate) {
                alert('Please accept term and condition before submitting the form')
            } else {
                const body =
                    {
                        username: props.location.state.username,
                        email: props.location.state.email,
                        password: props.location.state.password,
                        primary_name: name,
                        secondary_name: owner,
                        address,
                        place_of_incorporation: corpPlace,
                        country_of_incorporation: corpCountry === 'Other' ? otherCorpCountryValue : corpCountry,
                        date_of_incorporation: corpDate,
                        account_type: 'FINANCIAL',
                        contact_person: contactPerson,
                        id_number: nibNo,
                        phone_number: phoneNo,
                        business_nature: businessNature,
                        npwp_number: npwpNo,
                        id_document_image_url: documentImageUrl,
                    }

                if (isUpdate) {
                    CustomerDao.updateInfo(body).then(res => {
                        if (res.success) {
                            setSuccessMsg('Profile has been updated successfully!')
                        }
                    }).catch(err => console.log(err));
                } else {
                    CustomerDao.register(body).then(res => {
                        if (res.success) {
                            alert('You have been registered successfully. Admin approval is needed to create an order.')
                            history.push('/');
                        }
                    }).catch(err => console.log(err))
                }
            }
        } else {
            console.log(errorsTemp)
            setErrorMsg('One or more errors occured. Please check the form again.')
        }
    }

    return (
        <AppContainer active={isUpdate ? 'Account' : null} activeSubMenu={'Edit Account Details'}>
            <Container>
                <Row style={styles.header}>
                    Account Details (Financial Institution)
                </Row>

                <DefaultTextInput onKeyPress={onKeyPress} field={'FINANCIAL INSTITUTION NAME'} required value={name}
                                  errors={errors} onChange={event => setName(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'OWNER NAME'} required value={owner} errors={errors}
                                  onChange={event => setOwner(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'ADDRESS'} required value={address} errors={errors}
                                  onChange={event => setAddress(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'CITY OF INCORPORATION'} required value={corpPlace} errors={errors}
                                  onChange={event => setCorpPlace(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'DATE OF INCORPORATION'}
                                  value={corpDate}
                                  type={'date'} required errors={errors}
                                  onChange={event => {
                                      const errorsTemp = {};
                                      setErrors(errorsTemp)
                                      if(event.target.value && (!moment(event.target.value).isValid() || moment(event.target.value).isAfter(moment(new Date())))) {
                                          errorsTemp["DATE OF INCORPORATION"] = `Please input a valid date prior to today`;
                                          setErrors(errorsTemp)
                                      }

                                      setCorpDate(event.target.value)
                                  }}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'NO. NIB'} type={'tel'} required value={nibNo}
                                  errors={errors}
                                  onChange={(event) => {
                                      if (!isNaN(event.target.value)) setNibNo(event.target.value)
                                      else setNibNo(event.target.value.replace(/\D/g, ''))
                                  }}

                />

                <DefaultTextInput onKeyPress={onKeyPress} field={'PHONE NUMBER'} type={'tel'} required
                                  onChange={(event) => {
                                      if (!isNaN(event.target.value)) setPhoneNo(event.target.value)
                                      else setPhoneNo(event.target.value.replace(/\D/g, ''))
                                  }}
                                  value={phoneNo} errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'RUNNER / CONTACT PERSON'} required
                                  value={contactPerson}
                                  onChange={event => setContactPerson(event.target.value)}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'NO. NPWP'} type={'tel'} required
                                  onChange={event => setNpwpNo(event.target.value)}
                                  value={npwpNo} errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'BUSINESS NATURE'}
                                  onChange={event => setBusinessNature(event.target.value)} required
                                  value={isUpdate ? 'Money Changer' : null} errors={errors}/>

                <DefaultTextInput field={'EMAIL ADDRESS'} disabled
                                  value={isUpdate ? user.email : props.location.state.email} errors={errors}/>

                {isUpdate ? <DefaultTextInput onKeyPress={onKeyPress} field={'CIF NUMBER'} errors={errors} value={user.cif_number}
                                              disabled/> : null
                }

                <DefaultSelectInput field={'COUNTRY OF INCORPORATION'} required
                                    value={corpCountry ? {value: corpCountry, label: corpCountry} : null}
                                    onChange={option => setCorpCountry(option.value)}
                                    options={['Indonesia', 'Malaysia', 'Singapore', 'Other']} errors={errors}/>

                {corpCountry === 'Other' ? <DefaultTextInput onKeyPress={onKeyPress}
                                                             value={otherCorpCountryValue}
                                                             onChange={(event) => {
                                                                 setOtherCorpCountryValue(event.target.value)
                                                             }}
                                                             placeholder={'Please fill in your country of incorporation'}
                                                             errors={errors}/> : null
                }

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Col style={{display: 'flex', flexDirection: 'row', maxWidth: '608px'}}>
                        <DefaultFilePicker
                            url={documentImageUrl}
                            readOnly={isUpdate}
                            field={'UPLOAD NIB / BUSINESS LICENSE'} required
                            file={isUpdate && !file ? {name: user.secondary_name + ' ID'} : file}
                            setFile={setFile} isUploading={isUploading} errors={errors}/>

                        {isUpdate ?
                            <div style={{marginTop: 5}}>
                                <DefaultFilePicker
                                    title={'Upload New File'}
                                    noBackground
                                    file={isUpdate && !file ? {name: user.secondary_name + ' ID'} : file}
                                    setFile={setFile} errors={errors}/>
                            </div> : null
                        }
                    </Col>
                </Row>

                {isUpdate ?
                    <>
                        <DefaultTextInput field={'PASSWORD'} type={'password'} value={'123456789'} errors={errors}/>

                        <Row style={{marginBottom: 50}}>
                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{
                                    width: 'inherit',
                                    maxWidth: 580,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <a href={'/change-password'} style={{textDecorationLine: 'none'}}>
                                        <div style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#dce1e5',
                                            color: '#3f9e59',
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderRadius: 50
                                        }}>
                                            Change Password
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </> : null
                }

                {isUpdate ? null :
                    <DefaultCheckBox
                        isChecked={isChecked}
                        setChecked={setChecked}
                        description={'I hereby declare that the above information is true & correct to the best of my knowledge and belief'}/>
                }

                <div style={{fontSize: '80%', textAlign: 'center', color: errorMsg ? 'red' : 'green'}}>
                    {errorMsg ? errorMsg : successMsg ? successMsg : null}
                </div>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptSubmit}>
                        {isUpdate ? 'Save' : 'Sign Up'}
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}
