import httpRequest from "../util/httpRequest";

export default class OrderDao {
    static getAll = async () => {
        return new Promise(
            await httpRequest.set('v1/orders', 'GET')
        )
    }

    static getById = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}`, 'GET')
        )
    }

    static getHistory = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}/history`, 'GET')
        )
    }

    static createNew = async (body) => {
        return new Promise(
            await httpRequest.set(`v1/orders`, 'POST', JSON.stringify(body))
        )
    }

    static changeReceipt = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}/change_receipt`, 'POST', JSON.stringify(body))
        )
    }

    static complaint = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}/complaint`, 'POST', JSON.stringify(body))
        )
    }

    static updateComplaint = async (id, body) => {
        return new Promise(
            await httpRequest.set(`v1/complaint/${id}`, 'PUT', JSON.stringify(body))
        )
    }

    static checkIfUserAuthorized = async (id) => {
        return new Promise(
            await httpRequest.set(`v1/order/${id}/check`, 'GET')
        )
    }
}