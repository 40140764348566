import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import React, {useState} from "react";
import Palette from "../Palette";
import moment from "moment";
import history from "../history";

export default function OrderCard(props) {
    const {
        windowWidth,
        id,
        name,
        negative,
        status,
        amount,
        viewInvoice,
        uploadReceipt,
        complainOrder,
        onTrack,
        onShowInvoice,
        onComplain,
        createdAt,
        bankName,
        transferAmount,
        rate,
        fee,
        totalPaymentAmount,
        email,
        originalRate,
        originalFee
    } = props;

    let noOfButtons = 1;

    if (viewInvoice) noOfButtons++;
    if (uploadReceipt) noOfButtons++;

    const [isLinkHovered, setLinkHovered] = useState(false);
    const [detail, setDetail] = useState(false);

    return (
        <Row style={{color: '#737373', cursor: 'pointer'}} onClick={() => setDetail(!detail)}>
            <Col style={{
                margin: 15,
                paddingTop: 15,
                paddingBottom: 15,
                backgroundColor: '#f7f7f7',
                border: '1px #e0e0e0 solid'
            }}>
                <Row>
                    <Col style={{textAlign: 'center'}} md={2} lg={1} xs={3}>
                        <div style={{fontWeight: '600', fontSize: '.9em'}}>
                            {moment(createdAt).format('MMM')}
                        </div>


                        <div style={{fontSize: '2em', marginTop: -10, marginBottom: -10}}>
                            {moment(createdAt).format('DD')}
                        </div>

                        <div style={{fontWeight: '600', fontSize: '.85em'}}>
                            {moment(createdAt).format('HH:mm')}
                        </div>
                    </Col>
                    <Col md={4} xs={7}>
                        <div style={{fontWeight: '600'}}>
                            {name}
                            {detail ? null :
                                <a href={'#'}
                                   onMouseOver={() => setLinkHovered(true)}
                                   onMouseLeave={() => setLinkHovered(false)}
                                   style={{
                                       textDecorationLine: 'none',
                                       borderBottom: isLinkHovered ? complainOrder && complainOrder.status === 'RESOLVED' ? `1px solid ${Palette.COLOR_PRIMARY}` : '1px solid #c9cf32' : 'none',
                                       marginLeft: 6
                                   }}
                                   onClick={(e) => {
                                       e.stopPropagation()
                                       e.preventDefault();
                                       onComplain()
                                   }}><span
                                    style={{
                                        color: complainOrder && complainOrder.status === 'RESOLVED' ? Palette.COLOR_PRIMARY : '#c9cf32',
                                        fontFamily: 'Roboto',
                                        fontWeight: '500'
                                    }}>{complainOrder && complainOrder.status === 'WAITING' ? 'Order Complaint' : complainOrder && complainOrder.status === 'RESOLVING' ? 'Resolving complaint' : complainOrder && complainOrder.status === 'RESOLVED' ? 'Complaint resolved' : null}</span>
                                </a>
                            }
                        </div>

                        <div style={{color: negative ? 'red' : 'green'}}>
                            {status}
                        </div>

                        <div style={{fontSize: '.9em'}}>
                            Order ID: {id}
                        </div>
                    </Col>

                    <Col style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{
                            fontWeight: '600',
                            textAlign: 'right',
                            paddingRight: 5,
                            marginBottom: 15,
                            fontSize: '1.1em'
                        }}>
                            {amount}

                            <Col md={12} style={{textAlign: 'right', paddingRight: 0}}>
                                {originalRate ?
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onTrack()
                                        }}
                                        style={{
                                            backgroundColor: 'yellow',
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                            fontWeight: '700',
                                            borderRadius: 50,
                                            fontSize: '.7em',
                                            marginRight: 5
                                        }}
                                    >
                                    Rate Updated!
                                    </span> : null
                                }

                                {originalFee ?
                                    <span
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onTrack()
                                        }}
                                        style={{
                                            backgroundColor: 'yellow',
                                            paddingLeft: 20,
                                            paddingRight: 20,
                                            paddingTop: 3,
                                            paddingBottom: 3,
                                            fontWeight: '700',
                                            borderRadius: 50,
                                            fontSize: '.7em'
                                        }}>
                                    Fee Updated!
                                </span> : null
                                }
                            </Col>
                        </div>

                        <div style={{flex: 1}}/>

                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {detail ? null :
                                <Button style={{
                                    ...styles.button, marginBottom: 0,
                                    fontSize: '.9em',
                                    fontWeight: '600',
                                    width: 140
                                }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onTrack()
                                        }}>
                                    Track Order
                                </Button>
                            }
                        </div>
                    </Col>

                    {/*<Col>*/}

                    {/*</Col>*/}

                    {detail ?
                        <Col xs={12}>
                            <div style={{
                                width: '100%',
                                height: 1,
                                backgroundColor: '#878787',
                                marginTop: 15,
                                marginBottom: 15
                            }}/>

                            <Row>
                                <Col style={{fontWeight: '600', color: '#969696'}} md={12} lg={6}>
                                    <div style={{marginBottom: 5}}>Recipient Details</div>
                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Name:
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {name}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Email:
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {email}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Bank (Payer):
                                        </Col>
                                        <Col style={{
                                            wordBreak: 'break-all',
                                            paddingRight: 15,
                                        }}>
                                            {bankName}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5, display: 'flex'}}>
                                        <Col>
                                            Received Amount:
                                        </Col>
                                        <Col style={{paddingRight: 15}}>
                                            {amount}
                                        </Col>
                                    </Row>

                                </Col>

                                <Col style={{
                                    fontWeight: '600',
                                    color: '#969696',
                                    marginTop: windowWidth < 992 ? 20 : 0
                                }}>
                                    <div style={{marginBottom: 5}}>Payment Details</div>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Transfer Amount:
                                        </Col>

                                        <Col style={{textAlign: 'right',}}>
                                            {transferAmount}
                                        </Col>
                                    </Row>

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Exchange Rate:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {rate}
                                        </Col>
                                    </Row>

                                    {originalRate ?
                                        <Row style={{fontWeight: '400', marginBottom: 5}}>
                                            <Col>
                                            </Col>

                                            <Col style={{
                                                textAlign: 'right',
                                                color: '#94961b',
                                                fontSize: '.8em',
                                                fontWeight: '600'
                                            }}>
                                                Updated from {originalRate}
                                            </Col>
                                        </Row> : null
                                    }

                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Service Fee:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {fee}
                                        </Col>
                                    </Row>

                                    {originalFee ?
                                        <Row style={{fontWeight: '400', marginBottom: 5}}>
                                            <Col>
                                            </Col>

                                            <Col style={{
                                                textAlign: 'right',
                                                color: '#94961b',
                                                fontSize: '.8em',
                                                fontWeight: '600'
                                            }}>
                                                Updated from {originalFee}
                                            </Col>
                                        </Row> : null
                                    }


                                    <Row style={{fontWeight: '400', marginBottom: 5}}>
                                        <Col>
                                            Total Payment Amount:
                                        </Col>

                                        <Col style={{textAlign: 'right'}}>
                                            {totalPaymentAmount}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> : null
                    }


                    <Col xs={12} style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#e3e3e3',
                        marginTop: 10,
                        marginBottom: 10,
                        opacity: noOfButtons > 1 ? 1 : 0
                    }}/>

                    <Col>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Row style={{marginRight: 0}}>

                                {noOfButtons > 1 && windowWidth < 768 && detail ?
                                    <Col sm={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        paddingRight: 0,
                                        marginBottom: 8
                                    }}>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onComplain()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '700',
                                                width: 161,
                                                backgroundColor: 'transparent',
                                                color: complainOrder && complainOrder.status !== 'RESOLVED' ? '#cfc325' : Palette.COLOR_PRIMARY,
                                            }} variant={'link'}>
                                            {complainOrder && complainOrder.status === 'WAITING' ? 'Order Complaint' : complainOrder && complainOrder.status === 'RESOLVING' ? 'Resolving complaint' : complainOrder && complainOrder.status === 'RESOLVED' ? 'Complaint resolved' : 'Complain Order'}
                                        </Button>
                                    </Col> :
                                    (noOfButtons === 1 || windowWidth >= 768) && detail ?
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                onComplain()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '700',
                                                width: 161,
                                                backgroundColor: 'transparent',
                                                color: complainOrder && complainOrder.status !== 'RESOLVED' ? '#cfc325' : Palette.COLOR_PRIMARY,
                                                marginRight: 10,
                                            }} variant={'link'}>
                                            {complainOrder && complainOrder.status === 'WAITING' ? 'Order Complaint' : complainOrder && complainOrder.status === 'RESOLVING' ? 'Resolving complaint' : complainOrder && complainOrder.status === 'RESOLVED' ? 'Complaint resolved' : 'Complain Order'}
                                        </Button> : null}


                                {uploadReceipt && noOfButtons > 1 && windowWidth < 768 ?
                                    <Col sm={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        paddingRight: 0,
                                        marginBottom: 8
                                    }}>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                history.push('/create-order', {id})
                                            }}
                                            style={{
                                                ...styles.button,
                                                marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: '#d6d167',
                                                color: 'white',
                                                border: '1px #dbdbdb solid'
                                            }}>
                                            Upload Receipt
                                        </Button>
                                    </Col>

                                    : uploadReceipt && (noOfButtons === 1 || windowWidth >= 768) ?
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                history.push('/create-order', {id})
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: '#d6d167',
                                                color: 'white',
                                                border: '1px #dbdbdb solid',
                                                marginRight: detail ? 10 : 0
                                            }}>
                                            Upload Receipt
                                        </Button> : null}

                                {viewInvoice && noOfButtons > 1 && windowWidth < 768 ?
                                    <Col sm={12} style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        paddingRight: 0,
                                        marginBottom: 8
                                    }}>
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                onShowInvoice()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid'
                                            }}>
                                            View Invoice
                                        </Button>
                                    </Col>
                                    : viewInvoice && (noOfButtons === 1 || windowWidth >= 550) ?
                                        <Button
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                onShowInvoice()
                                            }}
                                            style={{
                                                ...styles.button, marginBottom: 0,
                                                fontSize: '.9em',
                                                fontWeight: '600',
                                                width: 140,
                                                backgroundColor: 'white',
                                                color: Palette.COLOR_PRIMARY,
                                                border: '1px #dbdbdb solid',
                                                marginRight: 10
                                            }}>
                                            View Invoice
                                        </Button>
                                        : null}

                                {noOfButtons > 1 && windowWidth < 768 && detail ?
                                    <Col sm={12} style={{display: 'flex', justifyContent: 'flex-end', paddingRight: 0}}>
                                        <Button style={{
                                            ...styles.button, marginBottom: 0,
                                            fontSize: '.9em',
                                            fontWeight: '600',
                                            width: 140
                                        }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onTrack()
                                                }}>
                                            Track Order
                                        </Button>
                                    </Col> :
                                    detail ? <Button style={{
                                        ...styles.button, marginBottom: 0,
                                        fontSize: '.9em',
                                        fontWeight: '600',
                                        width: 140,
                                    }} onClick={(e) => {
                                        e.stopPropagation();
                                        onTrack()
                                    }}>
                                        Track Order
                                    </Button> : null
                                }

                            </Row>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
