import AppContainer from "../reusables/AppContainer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import React, {useEffect, useState} from "react";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import DefaultFilePicker from "../reusables/DefaultFilePicker";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import UploadDao from "../daos/UploadDao";
import moment from "moment";
import CustomerDao from "../daos/CustomerDao";
import history from "../history";
import Form from "react-bootstrap/Form";

export default function IndividualDetailPage(props) {
    const isUpdate = props.match.params && props.match.params.action === 'update';
    const [errors, setErrors] = useState({})
    const [isChecked, setChecked] = useState(false); //indicate whether user has accepted term and condition


    const [file, setFile] = useState(null);
    const [isUploading, setUploading] = useState(false);
    let user = {};
    if (isUpdate) {
        user = props.location.state.user;
    }

    const [fullName, setFullName] = useState(user.primary_name);
    const [nickName, setNickName] = useState(user.secondary_name);
    const [address, setAddress] = useState(user.address);
    const [placeOfBirth, setPlaceOfBirth] = useState(user.place_of_incorporation);
    const [dateOfBirth, setDateOfBirth] = useState(Object.keys(user).length !== 0 ? moment(user.date_of_incorporation).format('YYYY-MM-DD') : null);
    const [ktpNo, setKtpNo] = useState(user.id_number);
    const [phoneNo, setPhoneNo] = useState(user.phone_number);
    const [contactPerson, setContactPerson] = useState(user.contact_person);
    const [businessNature, setBusinessNature] = useState(user.business_nature);
    const [npwpNo, setNpwpNo] = useState(user.npwp_number);
    const [gender, setGender] = useState(user.gender);
    const [nationality, setNationality] = useState(user.country_of_incorporation);
    const [documentImageUrl, setDocumentImageUrl] = useState(user.id_document_image_url);
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [beneficialOwnerType, setBeneficialOwnerType] = useState(Object.keys(user).length === 0 ? null : user.contact_person ? 'with-owner' : 'no-owner');
    const [otherCorpCountryValue, setOtherCorpCountryValue] = useState(user.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(user.country_of_incorporation) ? user.country_of_incorporation : null);

    const uploadId = () => {
        const formData = new FormData();

        formData.append('upload', file);

        UploadDao.uploadImage('INDIVIDUAL_ID', formData).then(res => {
            setUploading(false);
            setDocumentImageUrl(res.location);
        }).catch(err => {
            const errorsTemp = {...errors};
            errorsTemp["UPLOAD ID"] = 'An error occured';
            setErrors(errorsTemp);
            console.log(err)
        })
    }

    useEffect(() => {
        if (file) {
            setUploading(true)
            uploadId()
        }

    }, [file])

    const attemptSubmit = () => {
        const errorsTemp = {};
        let errorMsgTemp = null;
        setErrors(errorsTemp);
        setErrorMsg(null);
        setSuccessMsg(null)

        if (!fullName) errorsTemp["FULL NAME"] = 'Full name cannot be blank';
        if (!nickName) errorsTemp["NICKNAME"] = 'Nickname cannot be blank';
        if (!address) errorsTemp["ADDRESS"] = 'Address cannot be blank';
        if (!placeOfBirth) errorsTemp["PLACE OF BIRTH"] = 'Place of birth hasn\'t selected';
        if(!moment(dateOfBirth).isValid() || moment(dateOfBirth).isAfter(moment(new Date()))) errorsTemp["DATE OF BIRTH"] = `Please input a valid date prior to today`;
        if (!dateOfBirth) errorsTemp["DATE OF BIRTH"] = 'Date of birth hasn\'t set';
        if (!ktpNo) errorsTemp["NO. KTP"] = 'No. KTP cannot be blank';
        if (!phoneNo) errorsTemp["PHONE NUMBER"] = 'Phone number cannot be blank';
        if (!contactPerson && beneficialOwnerType === 'with-owner') errorsTemp["BENEFICIAL OWNER"] = 'Beneficial owner cannot be blank';
        if (!businessNature) errorsTemp["JOB / BUSINESS NATURE"] = 'Job / business nature cannot be blank';
        if (!npwpNo) errorsTemp["No. NPWP"] = 'No. NPWP nature cannot be blank';
        if (!gender) errorsTemp["GENDER"] = 'Gender hasn\'t selected';
        if (!nationality) errorsTemp["NATIONALITY"] = 'Nationality hasn\'t selected';
        if(nationality === 'Other' && !otherCorpCountryValue) errorsTemp["NATIONALITY"] = 'Nationality cannot be blank';
        if (!documentImageUrl) errorsTemp["UPLOAD ID"] = 'ID hasn`t uploaded';
        if(!beneficialOwnerType) errorMsgTemp = 'Please select whether this account has beneficial owner or not'

        if (Object.keys(errorsTemp).length === 0 && !errorMsgTemp) {
            if (!isChecked && !isUpdate) {
                alert('Please accept term and condition before submitting the form')
            } else {
                const body = {
                    username: props.location.state.username,
                    email: props.location.state.email,
                    password: props.location.state.password,
                    primary_name: fullName,
                    secondary_name: nickName,
                    address,
                    place_of_incorporation: placeOfBirth,
                    country_of_incorporation: nationality === 'Other' ? otherCorpCountryValue : nationality,
                    date_of_incorporation: dateOfBirth,
                    account_type: 'INDIVIDUAL',
                    contact_person: contactPerson,
                    id_document_image_url: documentImageUrl,
                    id_number: ktpNo,
                    phone_number: phoneNo,
                    business_nature: businessNature,
                    gender: gender.charAt(0),
                    npwp_number: npwpNo
                };

                if (isUpdate) {
                    CustomerDao.updateInfo(body).then(res => {
                        if (res.success) {
                            setSuccessMsg('Profile has been updated successfully!')
                        }
                    }).catch(err => console.log(err));
                } else {
                    CustomerDao.register(body).then(res => {
                        if (res.success) {
                            alert('You have been registered successfully. Admin approval is needed to create an order.')
                            history.push('/');
                        }
                    }).catch(err => console.log(err))
                }
            }
        } else {
            setErrorMsg(errorMsgTemp ? errorMsgTemp : 'One or more errors occured. Please check the form again.')
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptSubmit()
        }
    }

    return (
        <AppContainer active={isUpdate ? 'Account' : null} activeSubMenu={'Edit Account Details'}>
            <Container>
                <Row style={styles.header}>
                    Account Details (Individual)
                </Row>

                <DefaultTextInput onKeyPress={onKeyPress} field={'FULL NAME'} required value={fullName} errors={errors}
                                  onChange={event => setFullName(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'NICKNAME'} required value={nickName} errors={errors}
                                  onChange={event => setNickName(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'ADDRESS'} required
                                  onChange={event => setAddress(event.target.value)}
                                  value={address}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'PLACE OF BIRTH'} required
                                  onChange={event => setPlaceOfBirth(event.target.value)}
                                  value={placeOfBirth}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'DATE OF BIRTH'} type={'date'} required
                                  value={dateOfBirth}
                                  onChange={event => {
                                      const errorsTemp = {};
                                      setErrors(errorsTemp)
                                      if(event.target.value && (!moment(event.target.value).isValid() || moment(event.target.value).isAfter(moment(new Date())))) {
                                          errorsTemp["DATE OF BIRTH"] = `Please input a valid date prior to today`;
                                          setErrors(errorsTemp)
                                      }

                                      setDateOfBirth(event.target.value)
                                  }}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'NO. KTP'} type={'tel'} required
                                  value={ktpNo}
                                  onChange={(event) => {
                                      if (!isNaN(event.target.value)) setKtpNo(event.target.value)
                                      else setKtpNo(event.target.value.replace(/\D/g, ''))
                                  }}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'PHONE NUMBER'} type={'tel'} required
                                  onChange={(event) => {
                                      if (!isNaN(event.target.value)) setPhoneNo(event.target.value)
                                      else setPhoneNo(event.target.value.replace(/\D/g, ''))
                                  }}
                                  value={phoneNo} errors={errors}/>

                <Row style={{marginBottom: 5}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                            <Form.Label
                                style={{
                                    marginBottom: 3,
                                    fontWeight: '700',
                                    fontSize: '.9em',
                                    color: '#a0a4a7'
                                }}>
                                BENEFICIAL OWNER *
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{marginTop: -30, marginBottom: -10}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Row style={{width: '100%', marginLeft: 0}}>
                                    <Col xs={4} sm={3} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        fontSize: '.9em',
                                        color: '#a0a4a7',
                                    }}>
                                        <Form.Check
                                            type={'radio'}
                                            name={'beneficial-owner'}
                                            value={'with-owner'}
                                            checked={beneficialOwnerType === 'with-owner'}
                                            onChange={(event) => {
                                                setBeneficialOwnerType(event.target.value)
                                            }}
                                        />

                                        Yes,
                                    </Col>
                                    <Col style={{paddingRight: 0, paddingTop: 20}}>
                                        <Form.Control
                                            isInvalid={!!errors['BENEFICIAL OWNER']}
                                            type={'text'}
                                            onChange={(event) => setContactPerson(event.target.value)}
                                            value={contactPerson ? contactPerson: null}
                                        />

                                        {errors['BENEFICIAL OWNER'] ?
                                            <Form.Control.Feedback type="invalid">
                                                {errors['BENEFICIAL OWNER']}
                                            </Form.Control.Feedback> : null
                                        }
                                    </Col>
                                </Row>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{marginBottom: 5}}>
                    <Col style={{display: 'flex', justifyContent: 'center'}}>
                        <Form.Group style={{width: 'inherit', maxWidth: 580}}>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <Row style={{
                                    width: '100%',
                                    marginLeft: 0,
                                    fontWeight: '700',
                                    fontSize: '.9em',
                                    color: '#a0a4a7'
                                }}>

                                    <Col xs={4} sm={3} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: '700',
                                        fontSize: '.9em',
                                        color: '#a0a4a7',
                                    }}>
                                        <Form.Check
                                            value={'no-owner'}
                                            type={'radio'}
                                            name={'beneficial-owner'}
                                            checked={beneficialOwnerType === 'no-owner'}
                                            onChange={(event) => {
                                                setContactPerson(null);
                                                setBeneficialOwnerType(event.target.value)
                                            }}
                                        />

                                       No one
                                    </Col>
                                    <Col style={{paddingRight: 0, paddingTop: 20}}>
                                    </Col>
                                </Row>
                            </div>

                        </Form.Group>
                    </Col>
                </Row>

                <DefaultTextInput onKeyPress={onKeyPress} field={'JOB / BUSINESS NATURE'} required
                                  value={isUpdate ? "Entrepreneur" : null}
                                  onChange={event => setBusinessNature(event.target.value)}
                                  errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'NO. NPWP'} type={'tel'} required
                                  onChange={event => setNpwpNo(event.target.value)}
                                  value={npwpNo} errors={errors}/>

                <DefaultSelectInput onKeyPress={onKeyPress} field={'GENDER'} required
                                    value={gender ? {
                                        value: gender === 'M' ? 'Male' : 'Female',
                                        label: gender === 'M' ? 'Male' : 'Female'
                                    } : null}
                                    onChange={option => setGender(option.value)}
                                    options={['Male', 'Female']} errors={errors}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'EMAIL ADDRESS'} required disabled
                                  value={isUpdate ? user.email : props.location.state.email}
                                  errors={errors}/>

                {isUpdate ? <DefaultTextInput onKeyPress={onKeyPress} field={'CIF NUMBER'}
                                              disabled
                                              required value={user.cif_number} errors={errors}/> : null
                }

                <DefaultSelectInput field={'NATIONALITY'} required
                                    onChange={option => setNationality(option.value)}
                                    value={nationality ? {value: nationality, label: nationality} : null}
                                    options={['Indonesia', 'Malaysia', 'Singapore', 'Other']} errors={errors}/>

                {nationality === 'Other' ? <DefaultTextInput onKeyPress={onKeyPress}
                                                                   value={otherCorpCountryValue}
                                                                   onChange={(event) => {
                                                                       setOtherCorpCountryValue(event.target.value)
                                                                   }}
                                                                   placeholder={'Please fill in your country of incorporation'}
                                                                   errors={errors}/> : null
                }

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Col style={{display: 'flex', flexDirection: 'row', maxWidth: '608px'}}>
                        <DefaultFilePicker
                            url={documentImageUrl}
                            readOnly={isUpdate}
                            field={'UPLOAD ID'} required
                            file={isUpdate && !file ? {name: user.secondary_name + ' ID'} : file}
                            setFile={setFile} isUploading={isUploading} errors={errors}/>

                        {isUpdate ?
                            <div style={{marginTop: 5}}>
                                <DefaultFilePicker
                                    title={'Upload New File'}
                                    noBackground
                                    file={isUpdate && !file ? {name: user.secondary_name + ' ID'} : file}
                                    setFile={setFile} errors={errors}/>
                            </div> : null
                        }
                    </Col>
                </Row>

                {isUpdate ?
                    <>
                        <DefaultTextInput field={'PASSWORD'} type={'password'} value={'123456789'} errors={errors}
                                          disabled/>

                        <Row style={{marginBottom: 50}}>
                            <Col style={{display: 'flex', justifyContent: 'center'}}>
                                <div style={{
                                    width: 'inherit',
                                    maxWidth: 580,
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <a href={'/change-password'} style={{textDecorationLine: 'none'}}>
                                        <div style={{
                                            cursor: 'pointer',
                                            backgroundColor: '#dce1e5',
                                            color: '#3f9e59',
                                            paddingLeft: 30,
                                            paddingRight: 30,
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            borderRadius: 50
                                        }}>
                                            Change Password
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </> : null
                }

                {isUpdate ? null :
                    <DefaultCheckBox
                        isChecked={isChecked}
                        setChecked={setChecked}
                        description={'I hereby declare that the above information is true & correct to the best of my knowledge and belief'}/>
                }

                <div style={{fontSize: '80%', textAlign: 'center', color: errorMsg ? 'red' : 'green'}}>
                    {errorMsg ? errorMsg : successMsg ? successMsg : null}
                </div>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptSubmit}>
                        {isUpdate ? 'Save' : 'Sign Up'}
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}
