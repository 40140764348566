import httpRequest from "../util/httpRequest";

export default class RecipientDao {
    static add = async (body) => {
        return new Promise (
            await httpRequest.set('v1/recipients', 'POST', JSON.stringify(body))
        )
    }

    static getAll = async () => {
        return new Promise (
            await httpRequest.set('v1/recipients', 'GET')
        )
    }

    static getById = async (id) => {
        return new Promise (
            await httpRequest.set(`v1/recipient/${id}`, 'GET')
        )
    }

    static delete = async (id) => {
        return new Promise (
            await httpRequest.set(`v1/recipient/${id}`, 'DELETE')
        )
    }

    static update = async (id, body) => {
        return new Promise (
            await httpRequest.set(`v1/recipient/${id}`, 'PUT', JSON.stringify(body))
        )
    }
}