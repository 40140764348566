import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import {FaAngleDown, FaCaretDown, FaCaretUp, FaFilter, FaSearch} from "react-icons/fa";
import InputBase from '@material-ui/core/InputBase';
import OrderCard from "../reusables/OrderCard";
import {MdSort} from "react-icons/md";
import _ from "lodash";
import {AiOutlineClose} from "react-icons/ai";
import TrackOrderModal from "../reusables/TrackOrderModal";
import moment from "moment";
import InvoiceModal from "../reusables/InvoiceModal";
import ComplainModal from "../reusables/ComplainModal";
import Select, {components} from "react-select";
import DateRangePicker from "../reusables/DateRangePicker";
import OrderDao from "../daos/OrderDao";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import util from "../util/util";
import CURRENCY from "../assets/values/CURRENCY";
import CustomerDao from "../daos/CustomerDao";
import DefaultTextInput from "../reusables/DefaultTextInput";
import {TiWarning} from "react-icons/ti";
import {RiErrorWarningFill} from "react-icons/ri";

export default function OrderPage() {
    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)

    const sorterFields = [
        {
            key: "recipient_name",
            label: "Recipient Name",
        }, {
            key: "destination_currency",
            label: "Currency",
        }, {
            key: "created_at",
            label: "Order Date",
        }
    ]

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const sortData = (arr) => {
        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                // tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])


        return orderedBy

    }


    const MENU = {
        CURRENT_ORDERS: 'CURRENT_ORDERS',
        ORDER_HISTORY: 'ORDER_HISTORY'
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    const [isApiInvoked, setApiInvoked] = useState(false);
    const [filteredRange, setFilteredRange] = useState({});
    const [isAmountRangeCustom, setAmountRangeCustom] = useState(false);
    const [myInfo, setMyInfo] = useState({});
    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [recipientsOptions, setRecipientsOptions] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)
    const [hoveredMenu, setMenuHovered] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(MENU.CURRENT_ORDERS);
    const [isTrackOrderModalShown, setTrackOrderModalShown] = useState(false);
    const [isInvoiceModalShown, setInvoiceModalShown] = useState(false);
    const [isComplainModalShown, setComplainModalShown] = useState(false);
    const [isDateRangeHovered, setDateRangeHovered] = useState(false);
    const [isDateRangePickerShown, setDateRangePickerShown] = useState(false);
    const [orders, setOrders] = useState([]);
    const [trackedOrders, setTrackedOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    // const [noOfUnpaidOrders, setNoOfUnpaidOrders] = useState(0);
    // const [noOfProcessedOrders, setNoOfProcessedOrders] = useState(0);

    const [noOfWaitingForReceipt, setNoOfWaitingForReceipt] = useState(0);
    const [noOfWaitingApproval, setNoOfWaitingApproval] = useState(0);
    const [noOfFinishedOrders, setNoOfFinishedOrders] = useState(0);

    const [filters, setFilters] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [statusOptions, setStatusOptions] = useState([]);
    const amountRangeOptions = [
        {
            label: '< 1000',
            value: {max: 999}
        },
        {
            label: '1000 - 2000',
            value: {min: 1000, max: 1999}
        },
        {
            label: '> 2000',
            value: {min: 2000}
        },
        {
            label: 'Custom',
            value: {}
        }
    ]

    useEffect(() => {
        if (!isApiInvoked) {
            CustomerDao.getMyInfo().then(res => {
                console.log(res)

                setMyInfo(res)
            }).catch(err => console.log(err));

            getAllOrder();
        }

        if (statusOptions.length === 0) {
            const statusOptionsTemp = [];

            Object.keys(ORDER_STEPS).map(key => {
                statusOptionsTemp.push({value: ORDER_STEPS[key], label: ORDER_STEPS[key]})
            })

            setStatusOptions(statusOptionsTemp);
        }

        if (currenciesOptions.length === 0) {
            const currenciesTemp = [];

            Object.keys(CURRENCY).map(key => {
                const code = CURRENCY[key];

                currenciesTemp.push({value: code, label: code});
            })

            setCurrenciesOptions(currenciesTemp);
        }

        filters.map(filter => {
            if (filter.type === 'Amount Range') {
                setAmountRangeCustom(filter.value.label === 'Custom')
            }
        })

        setApiInvoked(true)

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize)
    }, [filters])

    const getAllOrder = () => {
        OrderDao.getAll().then(res => {
            console.log(res)
            setOrders(res)

            let noOfWaitingForReceiptTemp = 0;
            let noOfWaitingApprovalTemp = 0;
            let noOfFinishedOrdersTemp = 0;

            // let unpaidOrders = 0;
            // let processedOrders = 0;
            const recipientsTemp = [];

            res.map(order => {
                if (recipientsTemp.length === 0) recipientsTemp.push({
                    value: order.recipient_id,
                    label: order.recipient.name
                });
                else {
                    let found = false;

                    recipientsTemp.map(recipient => {
                        if (JSON.stringify(recipient) === JSON.stringify({
                            value: order.recipient_id,
                            label: order.recipient.name
                        })) found = true;
                    })

                    if (!found) recipientsTemp.push({value: order.recipient_id, label: order.recipient.name});
                }

                if(ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_PAYMENT || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.PAYMENT_REJECTED) noOfWaitingForReceiptTemp++;
                if(ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_APPROVAL) noOfWaitingApprovalTemp++;
                if(ORDER_STEPS[order.current_order_step] === ORDER_STEPS.ORDER_PROCESSED) noOfFinishedOrdersTemp++;

                // if (ORDER_STEPS[order.current_order_step] === ORDER_STEPS.ORDER_PROCESSED) processedOrders++;
                // if (ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.TRANSACTION_FAILED && ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.ORDER_PROCESSED && ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.PAYMENT_APPROVED) unpaidOrders++;
            })

            setRecipientsOptions(recipientsTemp)

            setNoOfWaitingForReceipt(noOfWaitingForReceiptTemp);
            setNoOfWaitingApproval(noOfWaitingApprovalTemp);
            setNoOfFinishedOrders(noOfFinishedOrdersTemp);

            // setNoOfUnpaidOrders(unpaidOrders);
            // setNoOfProcessedOrders(processedOrders)
        }).catch(err => console.log(err))
    }

    const handleResize = () => {
        console.log(window.innerWidth)
        setWindowWidth(window.innerWidth);
    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            // defaultValue={optionsArr[0]}
                            options={formattedSorterFields}
                            onChange={(obj, key) => {
                                console.log(obj, key)
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    const filterOrder = (order) => {
        if (!order.recipient.name.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const orderCard = (
            <OrderCard
                negative={ORDER_STEPS[order.current_order_step] === ORDER_STEPS.PAYMENT_REJECTED || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.TRANSACTION_FAILED}
                viewInvoice={ORDER_STEPS[order.current_order_step] === ORDER_STEPS.PAYMENT_APPROVED || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.ORDER_PROCESSED}
                onShowInvoice={() => {
                    setInvoiceModalShown(true)
                    setSelectedOrder(order)
                }}
                bankName={order.recipient.bank_name}
                detail
                complainOrder={order.complaint}
                onComplain={() => {
                    setSelectedOrder(order)
                    setComplainModalShown(true)
                }}
                uploadReceipt={ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_PAYMENT || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.PAYMENT_REJECTED || ORDER_STEPS[order.current_order_step] === ORDER_STEPS.WAITING_APPROVAL}
                onTrack={() => {
                    OrderDao.getHistory(order.order_number).then(res => {
                        setTrackedOrders(res);
                        setTrackOrderModalShown(true)
                    }).catch(err => console.log(err));
                }}
                windowWidth={windowWidth}
                createdAt={order.created_at}
                status={ORDER_STEPS[order.current_order_step]}
                id={order.order_number} name={order.recipient.name}
                email={order.recipient.email}
                transferAmount={order.source_currency + " " + util.thousandSeparator(order.transfer_amount)}
                totalPaymentAmount={order.source_currency + " " + util.thousandSeparator(order.source_amount)}
                amount={order.destination_currency + " " + util.thousandSeparator(order.destination_amount)}
                originalRate={order.original_rate !== 0 && order.original_rate !== order.currency_rate ? `${order.destination_currency} 1 = ${order.source_currency} ${util.thousandSeparator(1 / order.original_rate) === '0.00' ? order.original_rate : util.thousandSeparator(1 / order.original_rate)}` : null}
                rate={`${order.destination_currency} 1 = ${order.source_currency} ${util.thousandSeparator(1 / order.currency_rate) === '0.00' ? order.currency_rate : util.thousandSeparator(1 / order.currency_rate)}`}
                fee={`${order.source_currency} ${order.rupiah_fee === 0 ? 0 : util.thousandSeparator(order.rupiah_fee)}`}
                originalFee={order.original_fee !== 0 && order.original_fee !== order.rupiah_fee ? `${order.source_currency} ${order.original_fee === 0 ? 0 : util.thousandSeparator(order.original_fee)}` : null}
            />
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // const filterType = filter.type;

                // if (filterType === 'Currency' && filter.value !== order.destination_currency) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Date' && !moment(order.created_at).isBetween(filter.value.startDate, filter.value.endDate, 'date', '[]')) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Recipient' && filter.value.value !== order.recipient.id) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Status' && filter.value !== ORDER_STEPS[order.current_order_step]) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Amount Range') {
                //     console.log(order.destination_amount)
                //     console.log(filter.value.value)
                //
                //     if (filter.value.value.min && (order.destination_amount < filter.value.value.min)) show = false
                //
                //     if (filter.value.value.max && (order.destination_amount > filter.value.value.max)) show = false
                //
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Date' && moment(order.created_at).isBetween(filter.startDate, filter.endDate, 'date', '[]')) {
                        filtersResult[key].found = true
                    } else if (key === 'Currency' && filter === order.destination_currency) {
                        filtersResult[key].found = true
                    } else if (key === 'Recipient' && filter.value === order.recipient.id) {
                        filtersResult[key].found = true
                    } else if (key === 'Status' && filter === ORDER_STEPS[order.current_order_step]) {
                        filtersResult[key].found = true
                    } else if (key === 'Amount Range') {
                        if (!filter.value.min && filter.value.max && order.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        } else if (filter.value.min && !filter.value.max && filter.value.min <= order.destination_amount) {
                            filtersResult[key].found = true
                        } else if (filter.value.min <= order.destination_amount && order.destination_amount <= filter.value.max) {
                            filtersResult[key].found = true
                        }
                    }
                })
            })

            //PAWMXR02Y8
            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return orderCard
        } else return orderCard;
    }

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter.type === 'Amount Range') setAmountRangeCustom(false)
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            if (aFilter.type === filter.type && (aFilter.value === filter.value || filter.value.label === 'Custom')) {
                if (filter.value.label !== 'Custom') {
                    const filtersTemp = [...filters];
                    filtersTemp[idx] = filter;
                    setFilters(filtersTemp)
                }

                found = true;
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    return (
        <AppContainer active={'Orders'}>
            <ComplainModal updateData={getAllOrder} windowWidth={windowWidth} show={isComplainModalShown}
                           onClose={() => setComplainModalShown(false)} order={selectedOrder}/>

            <InvoiceModal
                myInfo={myInfo}
                windowWidth={windowWidth} show={isInvoiceModalShown}
                order={selectedOrder}
                onClose={() => setInvoiceModalShown(false)}/>

            <TrackOrderModal show={isTrackOrderModalShown} onClose={() => setTrackOrderModalShown(false)}
                             orders={trackedOrders}/>

            <DateRangePicker
                onDateChanged={(dateRange) => {
                    addFilter({type: 'Date', value: dateRange[0]})
                }}
                show={isDateRangePickerShown}
                onClose={() => setDateRangePickerShown(false)}/>

            <Container style={{paddingBottom: 100}}>
                {myInfo.approval_status === 'WAITING' ?
                    <Row style={{
                        backgroundColor: 'yellow',
                        paddingLeft: 25,
                        paddingRight: 25,
                        paddingTop: 12,
                        paddingBottom: 12,
                        marginLeft: 10,
                        marginRight: 10,
                        marginBottom: 30
                    }}>
                        <span style={{marginTop: -2, marginRight: 10}}><RiErrorWarningFill/></span> <span
                        style={{fontFamily: 'Roboto', fontWeight: '500', marginRight: 5}}>Warning!</span> <span
                        style={{marginTop: -1}}>This account is still waiting for admin approval.</span>
                    </Row> : null
                }

                <Row>
                    <Col
                        onClick={() => {
                            setAmountRangeCustom(false);
                            setFilteredRange({})
                            setSelectedMenu(MENU.CURRENT_ORDERS)
                            setFilters([])
                        }}
                        onMouseOver={() => setMenuHovered(MENU.CURRENT_ORDERS)}
                        onMouseLeave={() => setMenuHovered(null)}
                        style={{
                            textAlign: 'center',
                            fontWeight: '600',
                            color: selectedMenu === MENU.CURRENT_ORDERS || hoveredMenu === MENU.CURRENT_ORDERS ? Palette.COLOR_PRIMARY : '#737373',
                            fontSize: '1.2em',
                            cursor: 'pointer'
                        }}>
                        Current Orders
                        <div style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: selectedMenu === MENU.CURRENT_ORDERS || hoveredMenu === MENU.CURRENT_ORDERS ? Palette.COLOR_PRIMARY : '#737373',
                            marginTop: 5
                        }}/>
                    </Col>

                    <Col
                        onClick={() => {
                            setAmountRangeCustom(false);
                            setFilteredRange({})
                            setSelectedMenu(MENU.ORDER_HISTORY)
                            setFilters([])
                        }}
                        onMouseOver={() => setMenuHovered(MENU.ORDER_HISTORY)}
                        onMouseLeave={() => setMenuHovered(null)}
                        style={{
                            textAlign: 'center',
                            fontWeight: '600',
                            color: selectedMenu === MENU.ORDER_HISTORY || hoveredMenu === MENU.ORDER_HISTORY ? Palette.COLOR_PRIMARY : '#737373',
                            fontSize: '1.2em',
                            cursor: 'pointer'
                        }}>
                        Order History

                        <div style={{
                            width: '100%',
                            height: 1,
                            backgroundColor: selectedMenu === MENU.ORDER_HISTORY || hoveredMenu === MENU.ORDER_HISTORY ? Palette.COLOR_PRIMARY : '#737373',
                            marginTop: 5
                        }}/>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={6}>
                        <Row style={{padding: 15}}>
                            <Col style={{
                                backgroundColor: '#f7f7f7',
                                border: '1px #e0e0e0 solid',
                                borderRadius: 10,
                                marginRight: 10,
                                textAlign: 'center',
                                fontWeight: '600',
                                color: '#737373',
                                fontSize: '1.75em',
                                paddingTop: 5
                            }}>
                                {noOfWaitingForReceipt}
                                <div style={{fontSize: '.4em', fontWeight: '500', marginBottom: 8}}>WAITING FOR RECEIPT</div>
                            </Col>
                            <Col style={{
                                backgroundColor: '#f7f7f7',
                                border: '1px #e0e0e0 solid',
                                borderRadius: 10,
                                marginLeft: 10,
                                textAlign: 'center',
                                fontWeight: '600',
                                color: '#737373',
                                fontSize: '1.75em',
                                paddingTop: 5
                            }}>
                                {noOfWaitingApproval}

                                <div style={{fontSize: '.4em', fontWeight: '500', marginBottom: 8}}>WAITING APPROVAL
                                </div>
                            </Col>
                            <Col style={{
                                backgroundColor: '#f7f7f7',
                                border: '1px #e0e0e0 solid',
                                borderRadius: 10,
                                marginLeft: 10,
                                textAlign: 'center',
                                fontWeight: '600',
                                color: '#737373',
                                fontSize: '1.75em',
                                paddingTop: 5
                            }}>
                                {noOfFinishedOrders}

                                <div style={{fontSize: '.4em', fontWeight: '500', marginBottom: 8}}>FINISHED ORDERS
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    <Col style={{display: 'flex', justifyContent: 'flex-end', marginTop: 30}}>
                        <a href={myInfo.approval_status === 'APPROVED' ? '/create-order' : '#'}>
                            <Button
                                onClick={(e) => {
                                    if (myInfo.approval_status !== 'APPROVED') {
                                        e.preventDefault();

                                        if(myInfo.approval_status === 'REJECTED'){
                                            alert('Your application to Alisan Remit has been rejected. Please contact our customer service for more information.')
                                        }else{
                                            alert('Your account must be approved to create new order.')
                                        }

                                    }
                                }}
                                style={{
                                    ...styles.button,
                                    width: 160,
                                    fontSize: '.9em',
                                    fontWeight: '600',
                                    boxShadow: ' 0px 6px 5px 0px rgba(186,182,186,.8)',
                                    marginBottom: 60
                                }}>
                                Create New Order
                            </Button>
                        </a>
                    </Col>
                </Row>

                <Row>
                    {
                        windowWidth < 768 ? null : sortBtn
                    }
                    <Col style={{
                        display: windowWidth < 768 ? 'block' : 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-start'
                    }}>
                        <div style={{
                            border: '1px #d4d4d4 solid',
                            borderRadius: 10,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                            <InputBase
                                style={{fontSize: '.9em'}}
                                placeholder="Type keyword here..."
                                onChange={(event) => setSearchKeyword(event.target.value)}
                            />
                        </div>
                    </Col>

                    {
                        windowWidth < 768 ? sortBtn : null
                    }
                </Row>


                <div style={{
                    height: isFilterExpanded ? '100%' : 0,
                    opacity: isFilterExpanded ? 1 : 0,
                    transition: 'opacity .15s'
                }}>
                    <Row style={{marginTop: 20}}>
                        <Col xs={12} md={6}>

                            <div style={{color: Palette.COLOR_PRIMARY}}>
                                <Row>
                                    <Col xs={5} lg={4}>
                                        <FaFilter size={14} style={{marginRight: 23}}/>

                                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                    </Col>

                                    <Col>
                                        <Row>
                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <div
                                                    onMouseOver={() => setDateRangeHovered(true)}
                                                    onMouseLeave={() => setDateRangeHovered(false)}
                                                    onClick={() => setDateRangePickerShown(true)}
                                                    style={{
                                                        border: `1px ${isDateRangeHovered ? '#bababa' : '#e6e6e6'} solid`,
                                                        width: '100%',
                                                        paddingTop: 6,
                                                        paddingBottom: 6,
                                                        borderRadius: 4,
                                                        color: '#4f4f4f',
                                                        paddingLeft: 10,
                                                        display: 'flex', alignItems: 'center',
                                                        paddingRight: 9
                                                    }}>
                                                    <span style={{flex: 1, color: 'grey', cursor: 'default'}}>
                                                        Date Range
                                                    </span>

                                                    <FaAngleDown color={'black'}/>
                                                </div>
                                            </Col>

                                            <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                                <Select
                                                    placeholder={'Currency'}
                                                    onChange={(option) => addFilter({
                                                        type: 'Currency',
                                                        value: option.value
                                                    })}
                                                    // defaultValue={optionsArr[0]}
                                                    value={null}
                                                    options={currenciesOptions}
                                                    styles={styles.select}
                                                    components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col>

                            <Row>
                                {windowWidth < 768 ? <Col xs={5}/> : null}
                                <Col>
                                    <Row>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                value={null}
                                                onChange={(option) => addFilter({type: 'Recipient', value: option})}
                                                placeholder={'Recipient'}
                                                options={recipientsOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Status'}
                                                onChange={(option) => addFilter({type: 'Status', value: option.value})}
                                                value={null}
                                                options={statusOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>

                                        <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Amount Range'}
                                                onChange={(option) => addFilter({type: 'Amount Range', value: option})}
                                                value={null}
                                                options={amountRangeOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />

                                            {isAmountRangeCustom ?
                                                <DefaultTextInput
                                                    style={{marginTop: 12}}
                                                    placeholder={'Minimum Amount'}
                                                    errors={[]}
                                                    value={filteredRange.min}
                                                    onChange={(event) => {
                                                        const filtersTemp = [...filters];

                                                        filtersTemp.map((filter, idx) => {
                                                            if (filter.type === 'Amount Range') {
                                                                filtersTemp[idx].value.value.min = parseFloat(event.target.value.replace(/,/g, ''));
                                                            }
                                                        })

                                                        setFilters(filtersTemp);

                                                        const filteredRangeTemp = {...filteredRange};

                                                        if (event.target.value.length === 1 && event.target.value === "0") {
                                                            filteredRangeTemp.min = '';
                                                        } else {
                                                            filteredRangeTemp.min = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        }

                                                        setFilteredRange(filteredRangeTemp)
                                                    }}
                                                /> : null
                                            }

                                            {isAmountRangeCustom ?
                                                <DefaultTextInput
                                                    style={{marginTop: -10}}
                                                    placeholder={'Maximum Amount'}
                                                    errors={[]}
                                                    value={filteredRange.max}
                                                    onChange={(event) => {
                                                        const filtersTemp = [...filters];

                                                        filtersTemp.map((filter, idx) => {
                                                            if (filter.type === 'Amount Range') {
                                                                filtersTemp[idx].value.value.max = parseFloat(event.target.value.replace(/,/g, ''));
                                                            }
                                                        })

                                                        setFilters(filtersTemp);

                                                        const filteredRangeTemp = {...filteredRange};

                                                        if (event.target.value.length === 1 && event.target.value === "0") {
                                                            filteredRangeTemp.max = '';
                                                        } else {
                                                            filteredRangeTemp.max = event.target.value.replace(/\D/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                                        }

                                                        setFilteredRange(filteredRangeTemp)
                                                    }}
                                                /> : null
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>
                            {
                                chosenSorterField ?
                                    <div style={{
                                        borderRadius: 50,
                                        backgroundColor: '#e6e7e8',
                                        paddingLeft: 20,
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingRight: 10,
                                        marginRight: 10,
                                        marginTop: 10
                                    }}>
                                        {chosenSorterFieldLabel}
                                        <AiOutlineClose
                                            onClick={() => {
                                                setChosenSorterFieldLabel("")
                                                setChosenSorterField(null)
                                                setChosenSorterFieldOrder(1)
                                            }}
                                            style={{
                                                marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                    :
                                    null
                            }

                            {
                                filters.map(
                                    filter => {
                                        return (
                                            <div style={{
                                                borderRadius: 50,
                                                backgroundColor: '#e6e7e8',
                                                paddingLeft: 20,
                                                paddingTop: 8,
                                                paddingBottom: 8,
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingRight: 10,
                                                marginRight: 10,
                                                marginTop: 10
                                            }}>
                                                {filter.type}: {filter.type === 'Recipient' || filter.type === 'Amount Range' ? filter.value.label : filter.type === 'Date' ? moment(filter.value.startDate).format('DD/MM/YYYY') + ' - ' + moment(filter.value.endDate).format('DD/MM/YYYY') : filter.value}
                                                <AiOutlineClose
                                                    onClick={() => deleteFilter(filter)}
                                                    style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                            <a href={'#'}
                               style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                               onClick={(e) => {
                                   e.preventDefault();
                                   setFilterExpanded(false)
                               }}>
                                Collapse

                                <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                            </a>
                        </Col>
                    </Row>

                    <Row style={{
                        width: '100%',
                        height: 1,
                        backgroundColor: '#878787',
                        marginLeft: 0,
                        marginTop: 15,
                        marginBottom: 30
                    }}/>
                </div>

                {selectedMenu === MENU.CURRENT_ORDERS ?
                    <>
                        {
                            sortData(orders).map(order => {
                                if (ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.TRANSACTION_FAILED && ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.ORDER_PROCESSED && ORDER_STEPS[order.current_order_step] !== ORDER_STEPS.ORDER_REJECTED) {
                                    return filterOrder(order);
                                }
                            })
                        }
                    </> :
                    <>
                        {
                            sortData(orders).map(order => {
                                return filterOrder(order);
                            })
                        }
                    </>
                }
            </Container>
        </AppContainer>
    )
}
