import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import Select, {components} from "react-select";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import React, {useEffect, useState} from "react";
import {FaAngleDown, FaChevronLeft} from "react-icons/fa";
import util from "../util/util";
import CurrencyDao from "../daos/CurrencyDao";

export default function AmountSection(props) {
    const {onNext, onBack, show} = props;

    const [sourceAmount, setSourceAmount] = useState('')
    const [destinationAmount, setDestinationAmount] = useState('')

    const [currenciesMapper, setCurrenciesMapper] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [sourceCurrency, setSourceCurrency] = useState({});
    const [destinationCurrency, setDestinationCurrency] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [updateSource, setUpdateSource] = useState(false);
    const [updateDestination, setUpdateDestination] = useState(false);

    const onSubmit = () => {
        setErrorMsg(null)

        if (!sourceAmount || !destinationAmount) setErrorMsg('Please enter a valid amount')
        else if (parseFloat(destinationAmount.replace(/,/g, '')) < 1) {
            setErrorMsg('Converted money must be greater than zero')
        } else if (currenciesMapper[sourceCurrency.value].currency_code === currenciesMapper[destinationCurrency.value].currency_code) {
            setErrorMsg('Source and destination currencies must be different')
        } else if (destinationCurrency.minimum > parseFloat(destinationAmount.replace(/,/g, ''))) {
            setErrorMsg(`The minimum value for ${destinationCurrency.label} transaction is ${util.thousandSeparator(destinationCurrency.minimum)}`)
        } else {
            // console.log((destinationCurrency.minimum  + " > " + parseFloat(destinationAmount.replace(/,/g, ''))))
            onNext(currenciesMapper[sourceCurrency.value], parseFloat(sourceAmount.replace(/,/g, '')) - calculateFee(), currenciesMapper[destinationCurrency.value])
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            onSubmit()
        }
    }

    useEffect(() => {
        const currenciesMapperTemp = [];
        const currenciesTemp = [];

        if (show && currencies.length === 0) {
            CurrencyDao.getAll().then(res => {

                console.log(res)

                res.map((currency, idx) => {
                    if (idx === 0) {
                        setSourceCurrency({value: currency.currency_code, label: currency.currency_code})

                    } else if (idx === 1) {
                        setDestinationCurrency({
                            value: currency.currency_code,
                            label: currency.currency_code,
                            minimum: currency.minimum_transaction
                        })
                    }
                    
                    currenciesMapperTemp[currency.currency_code] = currency;

                    if (currency.active) {
                        currenciesTemp.push({
                            value: currency.currency_code,
                            label: currency.currency_code,
                            minimum: currency.minimum_transaction
                        })
                    }
                })


                setCurrenciesMapper(currenciesMapperTemp)
                setCurrencies(currenciesTemp)
            }).catch(err => console.log(err));
        }

        // if (updateSource && destinationAmount) {
        //     const destinationAmountTemp = parseFloat(destinationAmount.replace(/,/g, ''))
        //
        //     setUpdateSource(false)
        //
        //     if (!isNaN(destinationAmountTemp)) setSourceAmount(util.thousandSeparator(calculateSourceAmt(destinationAmountTemp)))
        // }
        //
        // if (updateDestination && sourceAmount) {
        //     const sourceAmountTemp = parseFloat(sourceAmount.replace(/,/g, ''))
        //
        //     setUpdateDestination(false)
        //
        //     if (!isNaN(sourceAmountTemp)) setDestinationAmount(util.thousandSeparator(calculateDestinationAmt(sourceAmountTemp)))
        // }
    }, [show, sourceCurrency, destinationCurrency])

    const selectStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected | state.isFocused ? 'white' : Palette.COLOR_PRIMARY,
            fontWeight: '600',
            backgroundColor: state.isSelected || state.isFocused ? Palette.COLOR_PRIMARY : 'white',
            cursor: 'pointer',

        }),
        singleValue: (provided, state) => {
            return {...provided, color: Palette.COLOR_PRIMARY, fontWeight: '600'};
        },
        control: provided => ({
            ...provided,
            borderWidth: 0,
            boxShadow: 'none'
        })
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={Palette.COLOR_PRIMARY}/>
                </components.DropdownIndicator>
            )
        );
    };

    const getNewRate = () => {
        if (currenciesMapper[sourceCurrency.value]) {
            const buyRate = currenciesMapper[sourceCurrency.value].rate - (currenciesMapper[sourceCurrency.value].rate * currenciesMapper[sourceCurrency.value].margin_buy);
            const sellRate = currenciesMapper[destinationCurrency.value].rate + (currenciesMapper[destinationCurrency.value].rate * currenciesMapper[destinationCurrency.value].margin_sell)

            return sellRate / buyRate;
        } else {
            return null
        }
    }

    const calculateSourceAmt = (destinationAmt) => {
        return (destinationAmt * getNewRate()) + calculateFee();
    }


    const calculateDestinationAmt = (sourceAmt) => {
        return (sourceAmt - calculateFee()) / getNewRate();
    }

    const calculateFee = () => {
        const FIXED_FEE = 100000
        const IDR_FEE = 10000

        let fee = FIXED_FEE

        if (currenciesMapper[destinationCurrency.value] && currenciesMapper[destinationCurrency.value].currency_code === 'IDR') {
            //10.000 will be charged every 10.000.000.000
            let multiplication = Math.floor((parseFloat(destinationAmount ? destinationAmount.replace(/,/g, '') / 10000000 : "0"))) + 1
            fee = IDR_FEE * multiplication
        }

        if (currenciesMapper[sourceCurrency.value]) {
            return fee / currenciesMapper[sourceCurrency.value].rate
        }
    }

    return (
        <Container fluid style={{display: show ? 'block' : 'none'}}>
            <Container style={{display: 'flex', justifyContent: 'center', paddingBottom: 50, marginTop: 60}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 500,
                    borderRadius: 15,
                    marginTop: 60,
                    paddingLeft: 50,
                    paddingRight: 50,
                    paddingTop: 40,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 50,
                }}>
                    <Row>
                        <Col xs={1} style={{paddingLeft: 0}}>
                            <FaChevronLeft style={{cursor: 'pointer'}} onClick={onBack}/>
                        </Col>

                        <Col style={{
                            color: Palette.COLOR_PRIMARY,
                            fontWeight: '500',
                            fontSize: '1.1em',
                            paddingLeft: 0,
                            paddingRight: 0
                        }}>
                            Fill transfer amount:
                        </Col>
                        <Col xs={1}/>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col xs={1} style={{paddingLeft: 0}}/>

                        <Col style={{
                            color: Palette.COLOR_PRIMARY,
                            fontWeight: '500',
                            fontSize: '1.1em',
                        }}>
                            <Row style={{
                                backgroundColor: 'white',
                                borderRadius: 5,
                                border: `1px solid ${Palette.COLOR_PRIMARY}`,
                                paddingTop: 3,
                                paddingBottom: 3
                            }}>
                                <Col style={{paddingRight: 0}} xs={6} md={4}>
                                    <Select
                                        onChange={(option) => {
                                            setDestinationAmount('');
                                            setSourceAmount('');

                                            setSourceCurrency({
                                                value: option.value,
                                                label: option.value
                                            })
                                        }}
                                        value={sourceCurrency}
                                        options={currencies}
                                        styles={selectStyles}
                                        components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                    />
                                </Col>

                                <Col style={{paddingLeft: 0}}>
                                    <Form.Control
                                        onKeyPress={onKeyPress}
                                        onChange={(event) => {
                                            // if(event.target.value.length === 1 && event.target.value === "0") {


                                            if (event.target.value.length === 1 && event.target.value === "0") {
                                                setSourceAmount('')
                                            } else {
                                                let sourceAmount = event.target.value.replace(/,/g, '').replace(/\s/g, '');
                                                let destinationAmount = calculateDestinationAmt(sourceAmount);

                                                if (!isNaN(sourceAmount)) {
                                                    setDestinationAmount(destinationAmount < 1 ? 0 : util.thousandSeparator(destinationAmount))
                                                    setSourceAmount(sourceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                                } else {
                                                    sourceAmount = sourceAmount.replace(/\D/g, '');

                                                    setDestinationAmount(destinationAmount < 1 ? 0 : util.thousandSeparator(destinationAmount))
                                                    setSourceAmount(sourceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                                }
                                            }
                                        }}
                                        style={{
                                            fontSize: "1em",
                                            borderColor: '#e6e6e6',
                                            borderWidth: 0,
                                            boxShadow: 'none',
                                            textAlign: 'right',
                                            color: Palette.COLOR_PRIMARY,
                                            fontWeight: '600'
                                        }} value={sourceAmount}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}/>
                    </Row>

                    <Row style={{marginTop: 30}}>
                        <Col xs={1}/>
                        <Col xs={3} md={2} style={{paddingLeft: 0}}>Rates:</Col>
                        {util.thousandSeparator(getNewRate()) !== '0.00' ?
                            <Col style={{paddingLeft: 0}}> {destinationCurrency.value} 1
                                = {sourceCurrency.value} {util.thousandSeparator(getNewRate())}</Col> : null}
                        <Col xs={1}/>
                    </Row>

                    {util.thousandSeparator(getNewRate()) === '0.00' ?
                        <Row>
                            <Col xs={1}/>
                            <Col style={{paddingLeft: 0}}>{sourceCurrency.value} 1
                                = {destinationCurrency.value} {getNewRate()}</Col>
                            <Col xs={1}/>
                        </Row> : null
                    }

                    <Row style={{marginTop: 5}}>
                        <Col xs={1}/>
                        <Col xs={3} md={2} style={{paddingLeft: 0}}>Fees:</Col>
                        <Col
                            style={{paddingLeft: 0}}>
                            {sourceCurrency.value} {calculateFee('SOURCE')}
                            {/*{currenciesMapper[destinationCurrency.value] ? util.thousandSeparator(currenciesMapper[destinationCurrency.value].fee) : null}*/}
                        </Col>
                        <Col xs={1}/>
                    </Row>

                    <Row style={{marginTop: 30}}>
                        <Col xs={1}/>
                        <Col style={{paddingLeft: 0}}>Recipient will get:</Col>
                        <Col xs={1}/>
                    </Row>

                    <Row style={{marginTop: 10}}>
                        <Col xs={1} style={{paddingLeft: 0}}/>

                        <Col style={{
                            color: Palette.COLOR_PRIMARY,
                            fontWeight: '500',
                            fontSize: '1.1em',
                        }}>
                            <Row style={{
                                backgroundColor: 'white',
                                borderRadius: 5,
                                border: `1px solid ${Palette.COLOR_PRIMARY}`,
                                paddingTop: 5,
                                paddingBottom: 5
                            }}>
                                <Col style={{paddingRight: 0}} xs={6} md={4}>
                                    <Select
                                        onChange={(option) => {

                                            console.log("minimum transaction", option)

                                            setDestinationAmount('');
                                            setSourceAmount('');

                                            setDestinationCurrency({
                                                value: option.value,
                                                label: option.value,
                                                minimum: option.minimum
                                            })
                                            // console.log(option.minimum + "!")
                                        }}
                                        value={destinationCurrency}
                                        options={currencies}
                                        styles={selectStyles}
                                        components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                    />
                                </Col>

                                <Col style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    fontWeight: '600',
                                    color: Palette.COLOR_PRIMARY,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    // paddingRight: 25
                                }}>
                                    <Col style={{paddingLeft: 0}}>
                                        <Form.Control
                                            onKeyPress={onKeyPress}
                                            onChange={(event) => {
                                                if (event.target.value.length === 1 && event.target.value === "0") {
                                                    setDestinationAmount('')
                                                } else {

                                                    let destinationAmount = event.target.value.replace(/,/g, '').replace(/\s/g, '');

                                                    if (!isNaN(destinationAmount)) {
                                                        const sourceAmount = calculateSourceAmt(destinationAmount);

                                                        setSourceAmount(sourceAmount !== currenciesMapper[destinationCurrency.value].fee ? util.thousandSeparator(sourceAmount) : '')
                                                        setDestinationAmount(destinationAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                                    } else {
                                                        destinationAmount = destinationAmount.replace(/\D/g, '');

                                                        const sourceAmount = calculateSourceAmt(destinationAmount);

                                                        setSourceAmount(sourceAmount !== currenciesMapper[destinationCurrency.value].fee ? util.thousandSeparator(sourceAmount) : '')
                                                        setDestinationAmount(destinationAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                                                    }
                                                }
                                            }}
                                            style={{
                                                fontSize: "1em",
                                                borderColor: '#e6e6e6',
                                                borderWidth: 0,
                                                boxShadow: 'none',
                                                textAlign: 'right',
                                                color: Palette.COLOR_PRIMARY,
                                                fontWeight: '600'
                                            }}
                                            value={destinationAmount}
                                        />
                                    </Col>
                                    {/*{currenciesMapper[destinationCurrency.value] && ((sourceAmount - currenciesMapper[destinationCurrency.value].fee) / currenciesMapper[destinationCurrency.value].rate) > 0 ? ((sourceAmount - currenciesMapper[destinationCurrency.value].fee) / currenciesMapper[destinationCurrency.value].rate).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : 0}*/}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1}/>
                    </Row>

                    <Row style={{marginTop: 25, paddingLeft: 15, display: 'flex', justifyContent: 'flex-end'}}>
                        <Col sm={12} style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'roboto',
                            fontWeight: '400',
                            color: 'red',
                            marginBottom: 20
                        }}>
                            {errorMsg}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button style={{...styles.button, marginBottom: 10}} onClick={onSubmit}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </Container>
    )
}
