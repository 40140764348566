import httpRequest from "../util/httpRequest";

export default class BankAccountDao {
    static getInfo = async () => {
        return new Promise(
            await httpRequest.set('v1/account_info', 'GET')
        )
    }

    static getBanks = async () => {
        return new Promise(
            await httpRequest.set('v1/banks', 'GET')
        )
    }
}
