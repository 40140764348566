import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import Container from "react-bootstrap/Container";
import React from "react";
import {FaChevronLeft} from "react-icons/fa";

export default function ConfirmationSection(props) {
    const {onNext, onBack, show} = props;

    return (
        <Container
            style={{display: show ? 'flex' : 'none', justifyContent: 'center', paddingBottom: 50, marginTop: 60}}>
            <Row style={{
                backgroundColor: '#f5f5f5',
                boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                width: '100%',
                maxWidth: 500,
                borderRadius: 15,
                marginTop: 60,
                paddingLeft: 50,
                paddingRight: 50,
                paddingTop: 40,
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 50,
            }}>
                <Row>
                    <Col xs={1} style={{paddingLeft: 0}}>
                        <FaChevronLeft style={{cursor: 'pointer'}} style={{opacity: 0}}/>
                    </Col>

                    <Col style={{
                        color: 'grey',
                        fontWeight: '600',
                        fontSize: '1.1em',
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'center'
                    }}>
                        Please await <span style={{color: Palette.COLOR_PRIMARY}}>confirmation e-mail</span> before
                        making any kind of payment.
                    </Col>
                    <Col xs={1}/>
                </Row>

                <Row style={{marginTop: 15}}>
                    <Col xs={1} style={{paddingLeft: 0}}>
                    </Col>

                    <Col style={{
                        color: 'grey',
                        fontWeight: '600',
                        fontSize: '1.1em',
                        paddingLeft: 0,
                        paddingRight: 0,
                        textAlign: 'center'
                    }}>
                        Please click on the attached <span style={{color: Palette.COLOR_PRIMARY}}>link</span> inside the
                        e-mail to proceed to
                        payment and receipt upload.

                    </Col>
                    <Col xs={1}/>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <a href={'/orders'}>
                        <Button style={{...styles.button, marginTop: 30, marginBottom: 5}}>
                            Go to Home
                        </Button>
                    </a>
                </Row>

                {/*<Row style={{display: 'flex', justifyContent: 'center'}}>*/}
                {/*    <Button style={{...styles.button, marginTop: 10, marginBottom: 10}} onClick={onNext}>*/}
                {/*        Next*/}
                {/*    </Button>*/}
                {/*</Row>*/}
            </Row>
        </Container>
    )
}