import httpRequest from "../util/httpRequest"

export default class CustomerDao {
    static register = async (body) => {
        return new Promise(
            await httpRequest.set('v1/customer/register', 'POST', JSON.stringify(body))
        )
    }

    static login = async (body) => {
        return new Promise(
            await httpRequest.set('v1/customer/login', 'POST', JSON.stringify(body))
        )
    }

    static getMyInfo = async () => {
        return new Promise(
            await httpRequest.set('v1/customer/me', 'GET')
        )
    }

    static updateInfo = async (body) => {
        return new Promise (
            await httpRequest.set('v1/customer/me', 'PUT', JSON.stringify(body))
        )
    }

    static changePassword = async (body) => {
        return new Promise (
            await httpRequest.set('v1/customer/me/change_password', 'PUT', JSON.stringify(body))
        )
    }

    static isEmailAvailable = async (email) => {
        return new Promise (
            await httpRequest.set(`/v1/customer/check?email=${email}`, 'GET')
        )
    }

    static forgotPassword = async (body) => {
        return new Promise (
            await httpRequest.set(`/v1/customer/access/request`, 'POST', JSON.stringify(body))
        )
    }

    static checkRecoveryToken = async (body) => {
        return new Promise (
            await httpRequest.set(`/v1/customer/access/test`, 'POST', JSON.stringify(body))
        )
    }

    static changePasswordWithRecoveryToken = async (body) => {
        return new Promise (
            await httpRequest.set(`/v1/customer/access/reset`, 'POST', JSON.stringify(body))
        )
    }
}