import React, {useEffect, useState} from "react";
import AppContainer from "../reusables/AppContainer";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import Container from "react-bootstrap/Container";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Button from "react-bootstrap/Button";
import CustomerDao from "../daos/CustomerDao";
import RecipientDao from "../daos/RecipientDao";
import util from "../util/util";
import CURRENCY from "../assets/values/CURRENCY";
import history from "../history";
import BankAccountDao from "../daos/BankAccountDao";

export default function ManageRecipientPage(props) {
    const [errors, setErrors] = useState({});
    const isUpdate = props.match.params.action === 'update';
    const [recipient, setRecipient] = useState({});
    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [otherCorpCountryValue, setOtherCorpCountryValue] = useState(recipient.country_of_incorporation && !['Indonesia', 'Malaysia', 'Singapore'].includes(recipient.country_of_incorporation) ? recipient.country_of_incorporation : null);
    const [banks, setBanks] = useState([])

    useEffect(() => {
        BankAccountDao.getBanks().then(res => {
            const banksTemp = [];

            res.map(bank => {
                if(bank.active) banksTemp.push(bank.name)
            })

            setBanks(banksTemp)
        }).catch(err => console.log(err));

        if (isUpdate) {
            RecipientDao.getById(props.location.state.id).then(res => {
                if(res.country && !['Indonesia', 'Malaysia', 'Singapore'].includes(res.country)) {
                    setOtherCorpCountryValue(res.country)
                }
                res.country = res.country && !['Indonesia', 'Malaysia', 'Singapore'].includes(res.country) ? 'Other' : res.country

                setRecipient(res)
            }).catch(err => console.log(err))
        }
    }, [])


    const attemptSubmit = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);
        setErrorMsg(false);
        setSuccessMsg(false)

        if (!util.validateEmail(recipient.email)) errorsTemp["EMAIL ADDRESS"] = "Please enter a valid email";
        if (!recipient.name) errorsTemp["FULL NAME / CORPORATE NAME"] = 'Full name / corporate name cannot be blank'
        if (!recipient.address) errorsTemp["ADDRESS"] = 'Address cannot be blank'
        if (!recipient.email) errorsTemp["EMAIL ADDRESS"] = 'Email cannot be blank'
        if (!recipient.telephone_number) errorsTemp["PHONE NUMBER"] = 'Phone number cannot be blank'
        if (!recipient.bank_account_number) errorsTemp["ACCOUNT NUMBER"] = 'Account number cannot be blank'
        if (!recipient.bank_name) errorsTemp["BANK NAME"] = 'Please select at least 1 bank'
        if (!recipient.bank_address) errorsTemp["BANK ADDRESS"] = 'Bank address cannot be blank'
        if (!recipient.swift_code) errorsTemp["SWIFT CODE"] = 'Swift code cannot be blank'
        if (!recipient.country) errorsTemp["COUNTRY"] = 'Please select at least 1 country'
        if(recipient.country === 'Other' && !otherCorpCountryValue) errorsTemp["COUNTRY"] = 'Country cannot be blank';
        if (!recipient.city) errorsTemp["BANK CITY"] = 'Please select at least 1 city'
        if (!recipient.province) errorsTemp["BANK PROVINCE"] = 'Please select at least 1 province'
        if (!recipient.relation) errorsTemp["RELATION"] = 'Please select at least 1 relation'
        if (Object.keys(errorsTemp).length === 0) {
            let body = {
                ...recipient,
                currency: CURRENCY[recipient.country]
            };

            if(recipient.country === 'Other') {
              body = {
                  ...recipient,
                  country: otherCorpCountryValue,
                  currency: CURRENCY[otherCorpCountryValue]
              }
            }

            if (!isUpdate) {
                RecipientDao.add(body).then(res => {
                    if (res.id) {
                        alert('New recipient has been created successfully!')
                        if(new URLSearchParams(props.location.search).get("redirect") === 'orders') history.push('/create-order', {recipient: res})
                        else history.push('/recipients')
                    }
                }).catch(err => {
                    console.log(err)

                    setErrorMsg('An error occurred');
                })
            } else {
                delete body.created_at;

                RecipientDao.update(props.location.state.id, body).then(res => {
                    if (res.success) {
                        setSuccessMsg('Recipient has been updated successfully!')
                    }
                }).catch(err => {
                    console.log(err)
                    setErrorMsg('An error occurred');
                })
            }
        } else {
            setErrorMsg('One or more errors occured. Please check the form again.')
        }
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptSubmit()
        }
    }

    return (
        <AppContainer active={'Recipients'}>
            <Row style={styles.header}>
                {isUpdate ? 'Edit' : 'Add New'} Recipient
            </Row>

            <DefaultTextInput field={'FULL NAME / CORPORATE NAME'} required value={recipient.name} errors={errors}
                              onKeyPress={onKeyPress}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.name = event.target.value;
                                  setRecipient(recipientTemp)
                              }}/>

            <DefaultTextInput field={'ADDRESS'} required value={recipient.address} errors={errors}
                              onKeyPress={onKeyPress}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.address = event.target.value;
                                  setRecipient(recipientTemp)
                              }}/>

            <DefaultTextInput field={'EMAIL ADDRESS'} type={'email'} required value={recipient.email}
                              onKeyPress={onKeyPress}
                              errors={errors}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.email = event.target.value;
                                  setRecipient(recipientTemp)
                              }}/>

            <DefaultTextInput field={'PHONE NUMBER'} type={'tel'} required value={recipient.telephone_number}
                              onKeyPress={onKeyPress}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};

                                  if(!isNaN(event.target.value)) recipientTemp.telephone_number = event.target.value;
                                  else recipientTemp.telephone_number = event.target.value.replace(/\D/g,'');

                                  setRecipient(recipientTemp)
                              }}
                              errors={errors}/>

            <DefaultTextInput field={'ACCOUNT NUMBER'} type={'tel'} required value={recipient.bank_account_number}
                              onKeyPress={onKeyPress}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};

                                  if(!isNaN(event.target.value)) recipientTemp.bank_account_number = event.target.value;
                                  else recipientTemp.bank_account_number = event.target.value.replace(/\D/g,'');

                                  setRecipient(recipientTemp)
                              }}
                              errors={errors}/>

            <DefaultSelectInput field={'BANK NAME'} required
                                value={recipient.bank_name ? {
                                    label: recipient.bank_name,
                                    value: recipient.bank_name
                                } : null}
                                onChange={(option) => {
                                    const recipientTemp = {...recipient};
                                    recipientTemp.bank_name = option.value;
                                    setRecipient(recipientTemp)
                                }}
                                options={banks} errors={errors}/>

            <DefaultTextInput field={'BANK ADDRESS'} required
                              onKeyPress={onKeyPress}
                              value={recipient.bank_address}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.bank_address = event.target.value;
                                  setRecipient(recipientTemp)
                              }}
                              errors={errors}/>

            <DefaultTextInput field={'BANK BRANCH'}
                              onKeyPress={onKeyPress}
                              value={recipient.bank_branch_name}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.bank_branch_name = event.target.value;
                                  setRecipient(recipientTemp)
                              }}
                              errors={errors}/>

            <DefaultTextInput field={'SWIFT CODE'} required
                              onKeyPress={onKeyPress}
                              value={recipient.swift_code}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.swift_code = event.target.value;
                                  setRecipient(recipientTemp)
                              }} errors={errors}/>

            <DefaultSelectInput field={'COUNTRY'} required
                                value={recipient.country ? {label: recipient.country, value: recipient.country} : null}
                                onChange={(option) => {
                                    const recipientTemp = {...recipient};
                                    recipientTemp.country = option.value;
                                    setRecipient(recipientTemp)
                                }}
                                options={['Indonesia', 'Singapore', 'Malaysia', 'Other']} errors={errors}/>

            {recipient.country === 'Other' ? <DefaultTextInput onKeyPress={onKeyPress}
                                                         value={otherCorpCountryValue}
                                                         onChange={(event) => {
                                                             setOtherCorpCountryValue(event.target.value)
                                                         }}
                                                         placeholder={'Please fill in your country of incorporation'}
                                                         errors={errors}/> : null
            }

            <DefaultTextInput field={'BANK CITY'} required
                              onKeyPress={onKeyPress}
                              value={recipient.city}
                              onChange={(event) => {
                                  const recipientTemp = {...recipient};
                                  recipientTemp.city = event.target.value;
                                  setRecipient(recipientTemp)
                              }} errors={errors}/>

            <DefaultTextInput field={'BANK PROVINCE'} required
                                value={recipient.province}
                                onChange={(event) => {
                                    const recipientTemp = {...recipient};
                                    recipientTemp.province = event.target.value;
                                    setRecipient(recipientTemp)
                                }}
                                options={['Jawa Barat', 'Luzon', 'Sabah']} errors={errors}/>

            <DefaultSelectInput field={'RELATION'} required
                                value={recipient.relation ? {
                                    label: recipient.relation,
                                    value: recipient.relation
                                } : null}
                                onChange={(option) => {
                                    const recipientTemp = {...recipient};
                                    recipientTemp.relation = option.value;
                                    setRecipient(recipientTemp)
                                }}
                                options={['Business']} errors={errors}/>

            <div style={{fontSize: '80%', textAlign: 'center', color: errorMsg ? 'red' : 'green'}}>
                {errorMsg ? errorMsg : successMsg ? successMsg : null}
            </div>

            <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                <Button style={styles.button} onClick={attemptSubmit}>
                    Save
                </Button>
            </Row>
        </AppContainer>
    )
}
