import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React, {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import OrderDao from "../daos/OrderDao";

export default function ComplainModal(props) {
    const {
        show,
        onClose,
        order,
        windowWidth,
        updateData
    } = props;

    const [complaint, setComplaint] = useState('');
    const [errorMsg, setErrorMsg] = useState(null);
    const [isEditMode, setEditMode] = useState(false);

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        console.log(order)

        if (show) {
            setComplaint(order.complaint ? order.complaint.complaint : '')
            disableScrolling()
        } else {
            setEditMode(false)
            setComplaint('')
            setErrorMsg(null)
            enableScrolling()
        }
    }, [show])

    const attemptSubmit = () => {
        if (!complaint) {
            setErrorMsg('Complaint must not be blank')
        } else {
            if(order.complaint) {
                OrderDao.updateComplaint(order.complaint.id, {
                    complaint
                }).then(res => {
                    alert('Complaint has been updated!')
                    updateData();
                    onClose()
                }).catch(err => setErrorMsg('An error occurred'))
            } else {
                OrderDao.complaint(order.order_number, {
                    complaint
                }).then(res => {
                    alert('Order has been complained!')
                    updateData();
                    onClose()
                }).catch(err => {
                    console.log(err)
                    setErrorMsg('An error occurred')
                })
            }
        }
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 600,
                    borderRadius: 15,
                    paddingBottom: 50
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em'}}>
                            Order #{order.order_number}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                style={{cursor: 'pointer'}} size={27} onClick={onClose}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%', marginLeft: 15, marginRight: 15}}>
                        <Col xs={12} style={{marginLeft: 0, marginBottom: 10}}>
                            Complain Form
                        </Col>
                        <Col>
                            <Form.Control as="textarea" rows="8"
                                          placeholder={'Type message here'}
                                          onChange={(event) => setComplaint(event.target.value)}
                                          disabled={order.complaint && !isEditMode}
                                          value={complaint}
                                          style={{
                                              border: '1px solid #4f4f4f',
                                              borderRadius: 0,
                                              resize: 'none',
                                              paddingLeft: 20,
                                              paddingTop: 15,
                                          }}/>
                        </Col>
                    </Row>

                    <Row style={{width: '100%'}}>
                        <Col sm={12} md={6} style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            color: 'red',
                            marginTop: windowWidth < 768 ? 10 : 0,
                            marginBottom: windowWidth < 768 ? 20 : 0,
                            marginLeft: 30
                        }}>
                            {errorMsg}
                        </Col>

                        {!order.complaint || (order.complaint && (order.complaint.status !== 'RESOLVING' && order.complaint.status !== 'RESOLVED')) ?
                            <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Button style={{...styles.button, marginBottom: 0, marginTop: 20}} onClick={() => {
                                    if (isEditMode || !order.complaint) attemptSubmit()
                                    else setEditMode(true)
                                }}>
                                    {isEditMode || !order.complaint ? 'Submit' : 'Edit'}
                                </Button>
                            </Col> : null
                        }
                    </Row>
                </Row>
            </Container>
        </div>
    )
}
