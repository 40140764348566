import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {DateRange} from "react-date-range";
import Palette from "../Palette";
import AppContainer from "./AppContainer";
import React, {useEffect, useState} from "react";

export default function DateRangePicker(props) {
    const {
        show,
        onClose,
        onDateChanged
    } = props;

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()
    }, [show])

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [isApplyBtnHovered, setApplyBtnHovered] = useState(false)
    const [isCancelBtnHovered, setCancelBtnHovered] = useState(false)

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s',

            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 350,
                    borderRadius: 5
                }}>
                    <Row style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginLeft: 0,
                        width: '100%',
                        marginTop: 10
                    }}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setDateRange([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={dateRange}
                            dayDisplayFormat={'D'}
                            weekdayDisplayFormat={'dd'}
                            startDatePlaceholder={'From'}
                            endDatePlaceholder={'Until'}
                            dateDisplayFormat={'D MMM YYYY'}
                            rangeColors={[Palette.COLOR_PRIMARY]}
                        />
                    </Row>

                    <Row style={{
                        width: '100%',
                        marginTop: 25,
                        marginBottom: 20,
                        color: Palette.COLOR_PRIMARY,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginLeft: 0,
                        paddingRight: 25
                    }}>
                            <span
                                onMouseOver={() => setCancelBtnHovered(true)}
                                onMouseLeave={() => setCancelBtnHovered(false)}
                                style={{
                                    cursor: 'pointer',
                                    fontWeight: '600',
                                    marginRight: 30,
                                    textDecorationLine: isCancelBtnHovered ? 'underline' : 'none',
                                    padding: 5
                                }} onClick={onClose}>
                                Cancel
                            </span>

                        <span
                            onMouseOver={() => setApplyBtnHovered(true)}
                            onMouseLeave={() => setApplyBtnHovered(false)}
                            style={{
                                cursor: 'pointer',
                                fontWeight: '600',
                                textDecorationLine: isApplyBtnHovered ? 'underline' : 'none',
                                padding: 5
                            }}
                            onClick={() => {
                                onDateChanged(dateRange)
                                onClose()
                            }}>
                                Apply
                            </span>
                    </Row>
                </Row>
            </Container>
        </div>
    )
}