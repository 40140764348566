import Palette from "./Palette";

export default {
    header: {
        display: 'flex',
        justifyContent: 'center',
        color: Palette.COLOR_PRIMARY,
        fontWeight: '600',
        fontSize: '1.5em',
        marginBottom: 40
    },
    subHeader: {
        textAlign: 'center',
        display: 'flex',
        color: '#737373',
        justifyContent: 'center',
        fontSize: '1.15em',
        fontWeight: '600',
        marginTop: -10,
        marginBottom: 40
    },
    button: {
        backgroundColor: Palette.COLOR_PRIMARY,
        borderWidth: 0,
        width: 150,
        paddingTop: 8,
        paddingBottom: 10,
        borderRadius: 100,
        fontSize: '1.15em',
        marginBottom: 100
    },
    iconDrawer: {
        marginRight: 13,
        fontSize: 21
    },
    iconDrawerHidden: {
        marginRight: 13,
        fontSize: 21,
        opacity: 0
    },
    select: {
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',

        }),
        control: provided => ({
            ...provided,
            borderColor: '#e6e6e6',
        })
    },
    selectError: {
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',

        }),
        control: provided => ({
            ...provided,
            borderColor: 'red',
        })
    },
}