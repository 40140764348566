import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import DefaultTextInput from "../reusables/DefaultTextInput";
import history from "../history";
import Button from "react-bootstrap/Button";
import AppContainer from "../reusables/AppContainer";
import React, {useState} from "react";
import util from "../util/util";
import CustomerDao from "../daos/CustomerDao";

export default function ForgotPasswordPage() {
    const [errors, setErrors] = useState({})
    const [email, setEmail] = useState(null);

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptSubmit()
        }
    }

    const attemptSubmit = () => {
        const errorsTemp = {};

        if (!util.validateEmail(email)) errorsTemp["EMAIL ADDRESS"] = 'Please enter a valid email';
        if (!email) errorsTemp["EMAIL ADDRESS"] = 'Email cannot be blank';
        if (Object.keys(errorsTemp).length === 0) {
            CustomerDao.forgotPassword({
                email
            }).then(res => {
                if (res.success) {
                    alert('An email has been sent to the address you have provided. Please follow the link in the email to complete your password reset request.')

                    history.push('/')
                }
            }).catch(err => {
                if (err.code) {
                    const errorsTemp = {};
                    if (err.code === 'CUSTOMER_NOT_FOUND') errorsTemp["EMAIL ADDRESS"] = 'User with this email address not found';
                    setErrors(errorsTemp)
                }
            })
        }

        setErrors(errorsTemp)
    }

    return (
        <AppContainer>
            <Container>
                <Row style={styles.header}>
                    Forgot Your Password?
                </Row>

                <Row style={styles.subHeader}>
                    Don't worry. Recovering the password is easy.<br/>
                    Just tell us the email you registered with.
                </Row>

                <DefaultTextInput
                    onKeyPress={onKeyPress}
                    field={'EMAIL ADDRESS'}
                    onChange={(event) => setEmail(event.target.value)} type={'email'} errors={errors}/>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptSubmit}>
                        Send
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}