import AppContainer from "../reusables/AppContainer";
import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import styles from "../styles";
import TrackOrderModal from "../reusables/TrackOrderModal";

export default function OrderCompletedPage(props) {
    const [isTrackOrderModalShown, setTrackOrderModalShown] = useState(false);

    return (
        <AppContainer active={'Orders'}>
            <TrackOrderModal show={isTrackOrderModalShown} onClose={() => setTrackOrderModalShown(false)}/>

            <Row style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 0,
                fontWeight: '600',
                color: 'grey',
                fontSize: '1.5em',
                marginTop: 50
            }}>
                Your order has been completed!
            </Row>

            <Row style={{display: 'flex', justifyContent: 'center', marginLeft: 0}}>
                <img src={require('../assets/placeholder.png')} style={{width: 300, height: 300, objectFit: 'contain'}}/>
            </Row>

            <Row style={{display: 'flex', justifyContent: 'center', marginLeft: 0, marginTop: 30}}>
                <Button style={{...styles.button, width: 220, paddingTop: 12, paddingBottom: 14}} onClick={() => setTrackOrderModalShown(true)}>
                    View Order Status
                </Button>
            </Row>
        </AppContainer>
    )
}