import Container from "react-bootstrap/Container";
import React, {useEffect, useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import ImageViewModal from "./ImageViewModal";

export default function DefaultFilePicker(props) {
    // const [file, setFile] = useState(props.file ? props.file : {});
    const acceptedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const inputFile = useRef(null);
    const {field, required, file, setFile, isUploading, errors} = props;
    const error = errors[field];
    const [isImageModalShown, setIsImageModalShown] = useState(false)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    function getExtension(path) {
        const basename = path.split(/[\\/]/).pop(),  // extract file name from full path ...
            // (supports `\\` and `/` separators)
            pos = basename.lastIndexOf(".");       // get last position of `.`

        if (basename === "" || pos < 1)            // if file name is empty or ...
            return "";                             //  `.` not found (-1) or comes first (0)

        return basename.slice(pos + 1);            // extract extension ignoring `.`
    }


    return (
        <>
            <ImageViewModal
                image_url={props.url}
                show={isImageModalShown} onClose={() => setIsImageModalShown(false)}
                windowWidth={windowWidth}
            />

            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={(e) => {
                const extension = e.target.files[0].name.split('.')[e.target.files[0].name.split('.').length - 1];
                let isAccepted = false;

                acceptedExtensions.map(acceptedExtension => {
                    if (acceptedExtension.toLowerCase() === extension.toLowerCase()) isAccepted = true;
                })

                console.log(e.target.files[0]);
                if (!isAccepted) alert(`Please upload only ${acceptedExtensions.toString().replace(/\,/gi, '/').toUpperCase()} extension file`)
                else setFile(e.target.files[0])
            }}/>

            <Row style={{marginBottom: 5}}>
                <Col style={{display: 'flex', justifyContent: 'center'}}>
                    <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                        <Form.Label
                            style={{
                                marginBottom: 6,
                                fontWeight: '700',
                                fontSize: '.9em',
                                color: '#a0a4a7'
                            }}>{field} {required ? ' *' : null}</Form.Label>

                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                            <a href={props.url && getExtension(props.url) === 'pdf' ? props.url : '#'} target={'_blank'} onClick={(e) => {
                                if((props.url && getExtension(props.url) !== 'pdf') || !props.readOnly) e.preventDefault();
                            }}>
                                <div style={{
                                    cursor: 'pointer',
                                    backgroundColor: props.noBackground ? 'white' : '#dce1e5',
                                    color: '#3f9e59',
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    borderRadius: 50
                                }} onClick={() => {
                                    console.log(props.url)

                                    if (!props.readOnly) inputFile.current.click()
                                    else if (getExtension(props.url) !== 'pdf') {
                                        setIsImageModalShown(true)
                                    }
                                }}>
                                    {isUploading ?
                                        <>
                                            Uploading

                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                style={{marginLeft: 10}}
                                            />
                                        </> :
                                        <>
                                            {props.title ? props.title : file ? file.name : 'UPLOAD'}
                                        </>
                                    }
                                </div>
                            </a>
                        </div>

                        {error ? <span style={{
                            marginLeft: '1rem',
                            marginTop: '.25rem',
                            fontSize: '80%',
                            width: '100%',
                            color: '#DC3545'
                        }}>{error}</span> : null}
                    </Form.Group>
                </Col>
            </Row>
        </>
    )
}
