import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {useEffect, useState} from "react";
import {AiOutlineClose} from "react-icons/ai";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import moment from "moment";

export default function TrackOrderModal(props) {
    const {
        show,
        onClose,
        orders
    } = props;

    const disableScrolling = () => {
        const x = window.scrollX;
        const y = window.scrollY;
        window.onscroll = function () {
            window.scrollTo(x, y);
        };
    }

    const enableScrolling = () => {
        window.onscroll = function () {
        };
    }

    useEffect(() => {
        if (show) disableScrolling()
        else enableScrolling()
    }, [show])

    // const progresses = [
    //     {
    //         date: '2020-06-20 14:34',
    //         status: 'Order approved - waiting for payment',
    //     },
    //     {
    //         date: '2020-06-20 14:40',
    //         status: 'Order approved - waiting for payment'
    //     },
    //     {
    //         date: '2020-06-20 14:51',
    //         status: 'Order paid - waiting payment approval by admin'
    //     },
    //     {
    //         date: '2020-06-20 14:51',
    //         status: 'Payment approved - transaction being processed'
    //     }
    // ];

    const [progresses, setProgresses] = useState([]);

    useEffect(() => {
        const progressesTemp = [];

        orders.map(order => {
            progressesTemp.push({date: moment(order.timestamp).format('YYYY-MM-DD HH:mm'), status: ORDER_STEPS[order.order_step]})
        })

        setProgresses(progressesTemp)
    }, [orders])

    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                right: 0,
                width: '100%',
                minHeight: '100vh',
                zIndex: show ? 2 : -1,
                backgroundColor: '#00000059',
                opacity: show ? 1 : 0,
                transition: 'zIndex .2s'
            }}>
            <Container
                style={{minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 600,
                    borderRadius: 15,
                    paddingBottom: 50
                }}>
                    <Row style={{width: '100%', marginTop: 30, marginLeft: 15, marginRight: 15, marginBottom: 40}}>
                        <Col xs={9} style={{fontSize: '1.2em'}}>
                            Order #{orders.length > 0 ? orders[0].order_number : null}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <AiOutlineClose
                                onClick={onClose}
                                style={{cursor: 'pointer'}} size={27}/>
                        </Col>
                    </Row>

                    {
                        progresses.map((progress, idx) => {
                            return (
                                <Row style={{
                                    width: '100%',
                                    marginLeft: 0,
                                    marginBottom: idx !== progresses.length - 1 ? -5 : 0
                                }}>
                                    <Col xs={2} style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        paddingRight: 0,
                                        paddingTop: 5
                                    }}>
                                        <div style={{
                                            border: '2px solid green',
                                            height: 14,
                                            width: 14,
                                            borderRadius: 15
                                        }}/>
                                        {idx !== progresses.length - 1 ?
                                            <div style={{flex: 1, width: 2, backgroundColor: 'green'}}/> : null}
                                    </Col>

                                    <Col style={{paddingLeft: 0, paddingBottom: 30, display: 'flex'}}>
                                        <div style={{
                                            fontWeight: '600',
                                            color: 'grey',
                                            marginRight: 5
                                        }}>{progress.date} : <span
                                            style={{
                                                fontWeight: '400',
                                                color: 'green',
                                                flex: 1
                                            }}>{progress.status}</span>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Row>
            </Container>
        </div>
    )
}