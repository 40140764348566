import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import {FaCaretDown, FaCaretUp, FaFilter, FaSearch, FaRegTrashAlt, FaPlus, FaAngleDown} from "react-icons/fa";
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import OrderCard from "../reusables/OrderCard";
import {MdSort} from "react-icons/md";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Form from "react-bootstrap/Form";
import {AiOutlineClose} from "react-icons/ai";
import {RiPencilFill} from "react-icons/ri";
import TrackOrderModal from "../reusables/TrackOrderModal";
import Table from "react-bootstrap/Table";
import InvoiceModal from "../reusables/InvoiceModal";
import ComplainModal from "../reusables/ComplainModal";
import Card from "react-bootstrap/Card";
import {useHistory} from "react-router-dom";
import Select, {components} from "react-select";
import RecipientDao from "../daos/RecipientDao";
import ConfirmationModal from "../reusables/ConfirmationModal";
import _ from "lodash";
import ORDER_STEPS from "../assets/values/ORDER_STEPS";
import OrderDao from "../daos/OrderDao";
import util from "../util/util";
import moment from "moment";
import CURRENCY from "../assets/values/CURRENCY";

export default function RecipientPage() {
    const [recipientsOptions, setRecipientsOptions] = useState([]);
    const [bankNameOptions, setBankNameOptions] = useState([]);

    const [chosenSorterField, setChosenSorterField] = useState(null)
    const [chosenSorterFieldLabel, setChosenSorterFieldLabel] = useState(null)
    const [chosenSorterFieldOrder, setChosenSorterFieldOrder] = useState(1)
    const [searchKeyword, setSearchKeyword] = useState('');

    const sorterFields = [
        {
            key: "name",
            label: "Name",
        }, {
            key: "currency",
            label: "Currency",
        }, {
            key: "bank_name",
            label: "Bank Name",
        }
    ]

    const formattedSorterFields = []

    sorterFields.forEach((obj, key) => {
        formattedSorterFields.push({
            value: key * 2,
            sorter: obj.key,
            label: obj.label + " ASC",
            order: 1,
        })
        formattedSorterFields.push({
            value: key * 2 + 1,
            sorter: obj.key,
            label: obj.label + " DESC",
            order: -1,
        })
    })

    const sortData = (arr) => {

        console.log(arr)

        if (chosenSorterField === null) {
            return arr
        }

        let tempArr = arr.map((obj) => {
            if (typeof obj[chosenSorterField] === "string" || obj[chosenSorterField] instanceof String) {
                let tempObj = {...obj}
                // tempObj[chosenSorterField] = tempObj[chosenSorterField].toLowerCase()
                return tempObj
            } else {
                return obj
            }
        })
        console.log(tempArr)

        let orderedBy = _.orderBy(tempArr, [chosenSorterField], [chosenSorterFieldOrder === 1 ? 'asc' : 'desc'])

        console.log(orderedBy)

        return orderedBy

    }


    const history = useHistory();

    const MENU = {
        CURRENT_ORDERS: 'CURRENT_ORDERS',
        ORDER_HISTORY: 'ORDER_HISTORY'
    }

    const [currenciesOptions, setCurrenciesOptions] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isFilterExpanded, setFilterExpanded] = useState(false)
    const [hoveredMenu, setMenuHovered] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(MENU.CURRENT_ORDERS);
    const [isTrackOrderModalShown, setTrackOrderModalShown] = useState(false);
    const [isInvoiceModalShown, setInvoiceModalShown] = useState(false);
    const [isComplainModalShown, setComplainModalShown] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [isConfirmationModalShown, setConfirmationModalShown] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [filters, setFilters] = useState([]);

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'black'}/>
                </components.DropdownIndicator>
            )
        );
    };

    const deleteRecipient = (id) => {
        RecipientDao.delete(id).then(res => {
            if (res.success) {
                alert('Recipient has been deleted successfully!')
                getRecipients()
                setConfirmationModalShown(false);
            }
        }).catch(err => console.log(err))
    }

    const deleteFilter = (filter) => {
        let deletedIdx = -1;

        filters.map((aFilter, idx) => {
            if (aFilter === filter) deletedIdx = idx;
        })

        const filtersTemp = [...filters];

        filtersTemp.splice(deletedIdx, 1);

        setFilters(filtersTemp);
    }

    const addFilter = (filter) => {
        let found = false;

        filters.map((aFilter, idx) => {
            if (aFilter.type === filter.type && aFilter.value === filter.value) {
                const filtersTemp = [...filters];
                filtersTemp[idx] = filter;
                setFilters(filtersTemp)

                found = true;
            }
        })

        if (!found) {
            const filtersTemp = [...filters];
            filtersTemp.push(filter);
            setFilters(filtersTemp);
        }
    }

    useEffect(() => {
        const currenciesTemp = [];

        Object.keys(CURRENCY).map(key => {
            const code = CURRENCY[key];

            currenciesTemp.push({value: code, label: code});
        })

        setCurrenciesOptions(currenciesTemp);

        getRecipients();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getRecipients = () => {
        const recipientsTemp = [];
        const bankNamesTemp = [];
        RecipientDao.getAll().then(res => {
            res.map(recipient => {
                let found = false;
                let bankNameFound = false;

                if (bankNamesTemp.length === 0) {
                    bankNamesTemp.push({
                        value: recipient.bank_name,
                        label: recipient.bank_name
                    })
                } else {
                    bankNamesTemp.map(bankName => {
                        if (JSON.stringify(bankName) === JSON.stringify({
                            value: recipient.bank_name,
                            label: recipient.bank_name
                        })) bankNameFound = true;
                    })

                    if (!bankNameFound) bankNamesTemp.push({value: recipient.bank_name, label: recipient.bank_name});
                }

                if (recipientsTemp.length === 0) {
                    recipientsTemp.push({
                        value: recipient.id,
                        label: recipient.name
                    });
                } else {
                    recipientsTemp.map(recipient => {
                        if (JSON.stringify(recipient) === JSON.stringify({
                            value: recipient.id,
                            label: recipient.name
                        })) found = true;
                    })

                    if (!found) recipientsTemp.push({value: recipient.id, label: recipient.name});
                }
            })

            setRecipientsOptions(recipientsTemp)
            setBankNameOptions(bankNamesTemp)
            setRecipients(res)
        }).catch(err => console.log(err))
    }

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    }

    const sortBtn = (
        <Col xs={12} md={6} style={{marginTop: windowWidth < 768 ? 30 : 15}}>
            <a href={'#'}
               style={{
                   color: Palette.COLOR_PRIMARY,
                   fontWeight: '600',
                   opacity: !isFilterExpanded ? 1 : 0,
                   height: !isFilterExpanded ? '100%' : 0
               }}
               onClick={(e) => {
                   e.preventDefault();
                   setFilterExpanded(true)
               }}>
                Sort and filter

                <FaCaretDown color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
            </a>

            <div style={{
                color: Palette.COLOR_PRIMARY,
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row>
                    <Col xs={5} lg={4}>
                        <MdSort size={22} style={{marginRight: 15}}/>

                        <span style={{fontWeight: '600', fontSize: '1.05em'}}>Sort By</span>
                    </Col>

                    <Col style={{paddingLeft: 0}} xs={7} lg={4}>
                        <Select
                            onChange={(obj, key) => {
                                console.log(obj, key)
                                setChosenSorterFieldOrder(obj.order)
                                setChosenSorterField(obj.sorter)
                                setChosenSorterFieldLabel(obj.label)
                            }}
                            options={formattedSorterFields}
                            styles={styles.select}
                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                        />
                    </Col>
                </Row>
            </div>
        </Col>
    );

    const renderEmptyRecipient = () => {
        return <Container style={{paddingBottom: 100}}>


            {/* <Row style={{display: 'flex', justifyContent: 'center', marginLeft: 0}}>
                <img src={require("../assets/placeholder.png")}
                     style={{width: 300, height: 300, objectFit: 'contain'}}/>
            </Row> */}

            <Row style={{
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 0,
                fontWeight: 'bold',
                color: '#b8c0c6',
                fontSize: '1.5em',
                marginTop: 50
            }}>
                No saved recipients yet!
            </Row>

            <Row style={{display: 'flex', justifyContent: 'center', marginLeft: 0, marginTop: 30}}>
                <a href={'/recipient/add'} style={{textDecorationLine: 'none'}}>
                    <Button style={{
                        ...styles.button,
                        width: 260,
                        paddingTop: 18, paddingBottom: 18,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 24
                    }} onClick={() => setTrackOrderModalShown(true)}>
                        <FaPlus style={{width: "15%", fontSize: "1.3em"}}/>
                        <span style={{width: "70%", textAlign: "center"}}>Add New Recipient</span>
                        <span style={{width: "15%"}}/>
                    </Button>
                </a>
            </Row>
        </Container>
    }

    const filterRecipient = (recipient) => {
        if (!recipient.name.toLowerCase().includes(searchKeyword.toLowerCase())) return;

        const recipientCard = (
            <Col md={4} style={{
                padding: 20,
            }}
            >

                <Card style={{
                    width: "100%",
                    borderRadius: 20,
                    boxShadow: '0px 3px 3px 0px rgba(0,0,0,0.2)',
                    paddingTop: 15,
                    paddingLeft: 15,
                    paddingBottom: 20,
                    fontSize: "0.9em",
                    height: "10em",
                    color: "grey",
                    display: "flex",
                    flexDirection: "row"
                }}>

                    <div style={{
                        flex: 1
                    }}>
                        <b style={{color: "grey"}}>{recipient.name}</b>
                        <br/>
                        <br/>
                        <p>
                            {recipient.country}<br/>
                            {recipient.bank_name}<br/>
                            {recipient.bank_account_number}
                        </p>
                    </div>

                    <div style={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        color: Palette.COLOR_PRIMARY,
                        justifyContent: "center",
                        fontSize: "1.5em"
                    }}>

                        <img
                            style={{
                                flex: 1,
                                height: "1.2em",
                                objectFit: "contain",
                                cursor: 'pointer'
                            }}
                            src={require("../assets/moneyexchange.png")}
                            onClick={() => history.push('/create-order', {recipient})}
                        />
                        <RiPencilFill style={{
                            flex: 1,
                            fontSize: "1em",
                            color: "#2096fb",
                            cursor: 'pointer'
                        }} onClick={() => history.push('/recipient/update', {id: recipient.id})}/>
                        <FaRegTrashAlt
                            onClick={() => {
                                setSelectedId(recipient.id);
                                setConfirmationModalShown(true)
                            }}
                            style={{
                                flex: 1,
                                fontSize: "1em",
                                color: "#ea5f5f",
                                cursor: 'pointer'
                            }}/>

                    </div>

                </Card>
            </Col>
        )

        if (filters.length !== 0) {
            let show = true;
            let filtersResult = {};

            for (let idx = 0; idx < filters.length; idx++) {
                const filter = filters[idx];

                if (filtersResult[filter.type]) {
                    filtersResult[filter.type].filters.push(filter.value)
                } else {
                    filtersResult = {
                        ...filtersResult,
                        [filter.type]: {
                            filters: [filter.value],
                            found: false
                        }
                    }
                }
                // const filterType = filter.type;
                //
                // console.log(filter)
                //
                // if (filterType === 'Currency' && filter.value !== recipient.currency) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Bank Name' && filter.value.value !== recipient.bank_name) {
                //     show = false;
                //     break;
                // }
                //
                // if (filterType === 'Name' && (filter.value.label !== recipient.name) && (filter.value.value !== recipient.id)) {
                //     show = false;
                //     break;
                // }
            }

            Object.keys(filtersResult).map(key => {
                filtersResult[key].filters.map(filter => {
                    if (key === 'Currency' && filter === recipient.currency) {
                        filtersResult[key].found = true
                    } else if (key === 'Bank Name' && filter.value === recipient.bank_name) {
                        filtersResult[key].found = true
                    } else if (key === 'Name' && filter.value === recipient.id) {
                        filtersResult[key].found = true
                    }
                })
            })

            Object.keys(filtersResult).map(key => {
                if (!filtersResult[key].found) show = false
            })

            if (show) return recipientCard
        } else return recipientCard;
    }

    const renderRecipients = () => {
        return <Container style={{paddingBottom: 100}}>

            <Row>

                <Col
                    lg={12}
                    style={styles.header}>
                    Recipient List
                </Col>

            </Row>

            <Row>
                {
                    windowWidth < 768 ? null : sortBtn
                }
                <Col style={{
                    display: windowWidth < 768 ? 'block' : 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-start'
                }}>
                    <div style={{
                        border: '1px #d4d4d4 solid',
                        borderRadius: 10,
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <FaSearch color={Palette.COLOR_PRIMARY} style={{marginRight: 20, marginLeft: 15}}/>

                        <InputBase
                            style={{fontSize: '.9em'}}
                            placeholder="Type keyword here..."
                            onChange={(event) => setSearchKeyword(event.target.value)}
                        />
                    </div>
                </Col>

                {
                    windowWidth < 768 ? sortBtn : null
                }
            </Row>

            <div style={{
                height: isFilterExpanded ? '100%' : 0,
                opacity: isFilterExpanded ? 1 : 0,
                transition: 'opacity .15s'
            }}>
                <Row style={{marginTop: 20}}>
                    <Col xs={12} md={6}>

                        <div style={{color: Palette.COLOR_PRIMARY}}>
                            <Row>
                                <Col xs={5} lg={4}>
                                    <FaFilter size={14} style={{marginRight: 23}}/>

                                    <span style={{fontWeight: '600', fontSize: '1.05em'}}>Filter By</span>
                                </Col>

                                <Col>
                                    <Row>
                                        <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                placeholder={'Name'}
                                                value={null}
                                                options={recipientsOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                                onChange={(option) => addFilter({type: 'Name', value: option})}
                                            />
                                        </Col>

                                        <Col md={12} lg={6} style={{paddingLeft: 0, marginBottom: 10}}>
                                            <Select
                                                onChange={(option) => addFilter({
                                                    type: 'Currency',
                                                    value: option.value
                                                })}
                                                placeholder={'Currency'}
                                                value={null}
                                                options={currenciesOptions}
                                                styles={styles.select}
                                                components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col>

                        <Row>
                            {windowWidth < 768 ? <Col xs={5}/> : null}
                            <Col>
                                <Row>

                                    <Col md={6} lg={4} style={{paddingLeft: 0, marginBottom: 10}}>
                                        <Select
                                            placeholder={'Bank Name'}
                                            value={null}
                                            options={bankNameOptions}
                                            styles={styles.select}
                                            components={{DropdownIndicator, IndicatorSeparator: () => null}}
                                            onChange={(option) => addFilter({type: 'Bank Name', value: option})}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row style={{marginTop: 10}}>
                    <Col style={{display: 'flex', flexWrap: 'wrap'}} xs={12} md={8}>
                        {
                            chosenSorterField ?
                                <div style={{
                                    borderRadius: 50,
                                    backgroundColor: '#e6e7e8',
                                    paddingLeft: 20,
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingRight: 10,
                                    marginRight: 10,
                                    marginTop: 10
                                }}>
                                    {chosenSorterFieldLabel}
                                    <AiOutlineClose
                                        onClick={() => {
                                            setChosenSorterFieldLabel("")
                                            setChosenSorterField(null)
                                            setChosenSorterFieldOrder(1)
                                        }}
                                        style={{
                                            marginTop: 3, marginLeft: 10, cursor: 'pointer'
                                        }}
                                    />
                                </div>
                                :
                                null
                        }

                        {
                            filters.map(
                                filter => {
                                    return (
                                        <div style={{
                                            borderRadius: 50,
                                            backgroundColor: '#e6e7e8',
                                            paddingLeft: 20,
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingRight: 10,
                                            marginRight: 10,
                                            marginTop: 10
                                        }}>
                                            {filter.type}: {filter.type === 'Name' || filter.type === 'Bank Name' ? filter.value.label : filter.value}
                                            <AiOutlineClose
                                                onClick={() => deleteFilter(filter)}
                                                style={{marginTop: 3, marginLeft: 10, cursor: 'pointer'}}/>
                                        </div>
                                    )
                                }
                            )
                        }
                    </Col>

                    <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <a href={'#'}
                           style={{color: Palette.COLOR_PRIMARY, fontWeight: '600'}}
                           onClick={(e) => {
                               e.preventDefault();
                               setFilterExpanded(false)
                           }}>
                            Collapse

                            <FaCaretUp color={Palette.COLOR_PRIMARY} style={{marginLeft: 10}}/>
                        </a>
                    </Col>
                </Row>

                <Row style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: '#878787',
                    marginLeft: 0,
                    marginTop: 15,
                    marginBottom: 30
                }}/>
            </div>

            <Row style={{marginTop: "2em"}}
            >

                {
                    sortData(recipients).map((obj, key) => {
                        return filterRecipient(obj)
                    })
                }


                <Col md={4} style={{
                    padding: 20,
                }}>
                    <a href={'/recipient/add'} style={{textDecorationLine: 'none'}}>
                        <Card style={{
                            width: "100%",
                            borderRadius: 20,
                            boxShadow: '2px 4px 12px 4px rgba(59,159,104,0.7)',
                            height: "10em",
                            fontSize: "0.9em",
                            color: Palette.COLOR_PRIMARY,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer"
                        }}>

                            <span style={{
                                width: "20%",
                                fontSize: "5em",
                                display: "flex",
                                flexDirection: "center",
                                alignItems: "center",
                                marginLeft: "0.2em",
                                paddingBottom: "0.2em"
                            }}>
                                +
                            </span>
                            <span style={{
                                width: "60%",
                                fontSize: "1.6em",
                                textAlign: "center"
                            }}>
                                Add New Recipient
                            </span>

                            <span style={{
                                width: "20%",
                            }}>
                            </span>

                        </Card>
                    </a>
                </Col>

            </Row>

        </Container>
    }

    return (
        <AppContainer active={'Recipients'}>
            <ConfirmationModal show={isConfirmationModalShown} onCancel={() => setConfirmationModalShown(false)}
                               title={'Are you sure you want to delete this recipient?'}
                               onConfirm={() => deleteRecipient(selectedId)}/>

            {recipients.length > 0 ?
                renderRecipients() :
                renderEmptyRecipient()}

        </AppContainer>
    )
}
