import AppContainer from "../reusables/AppContainer";
import React, {useCallback, useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import {GoPlus} from "react-icons/go";
import RecipientSection from "../reusables/RecipientSection";
import {FaAngleDown, FaChevronLeft} from "react-icons/fa";
import Select, {components} from "react-select";
import AmountSection from "../reusables/AmountSection";
import ConfirmationSection from "../reusables/ConfirmationSection";
import history from "../history";
import PaymentSection from "../reusables/PaymentSection";
import OrderDao from "../daos/OrderDao";
import CustomerDao from "../daos/CustomerDao";

export default function CreateOrderPage(props) {
    const STEP = {
        RECIPIENT: 0,
        AMOUNT: 1,
        CONFIRMATION: 2,
        PAYMENT: 3
    }

    const [currentStep, setCurrentStep] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [recipient, setRecipient] = useState({});
    const [orderPurpose, setOrderPurpose] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [myInfo, setMyInfo] = useState({});

    useEffect(() => {
        CustomerDao.getMyInfo().then(res => {
            if(res.approval_status !== 'APPROVED') {

                if(res.approval_status === 'REJECTED'){
                    alert('Your application to Alisan Remit has been rejected. Please contact our customer service for more information.')
                }else{
                    alert('Your account must be approved to create new order.')
                }
                history.push('/')
            }
        }).catch(err => console.log(err));

        if(props.match.params && props.match.params.orderId) {
            setCurrentStep(STEP.PAYMENT)
        } else if(props.location.state && props.location.state.id) setCurrentStep(STEP.PAYMENT)
        else setCurrentStep(STEP.RECIPIENT)

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    const handleResize = () => {
        console.log(window.innerWidth)
        setWindowWidth(window.innerWidth)
    }

    return (
        <AppContainer active={'Orders'}>
            <Container fluid
                       style={{backgroundColor: '#f7f7f7', position: 'fixed', top: 100, paddingTop: 30, zIndex: 1}}>
                <Container>
                    <Row>
                        <Col style={{display: 'flex', alignItems: 'center', paddingRight: 0}}>
                            <div style={{height: 1, flex: 1, backgroundColor: 'grey', opacity: 0}}/>
                            <div style={{
                                height: 26,
                                width: 26,
                                borderRadius: 20,
                                backgroundColor: Palette.COLOR_PRIMARY
                            }}/>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.RECIPIENT + 1 <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                        </Col>

                        <Col style={{paddingLeft: 0, paddingRight: 0, display: "flex", alignItems: 'center'}}>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.AMOUNT <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                            <div style={{
                                height: STEP.AMOUNT <= currentStep ? 26 : 14,
                                width: STEP.AMOUNT <= currentStep ? 26 : 14,
                                borderRadius: 20,
                                backgroundColor: STEP.AMOUNT <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.AMOUNT + 1 <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                        </Col>

                        <Col style={{paddingLeft: 0, paddingRight: 0, display: "flex", alignItems: 'center'}}>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.CONFIRMATION <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                            <div style={{
                                height: STEP.CONFIRMATION <= currentStep ? 26 : 14,
                                width: STEP.CONFIRMATION <= currentStep ? 26 : 14,
                                borderRadius: 20,
                                backgroundColor: STEP.CONFIRMATION <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.CONFIRMATION + 1 <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>
                        </Col>

                        <Col style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                height: 1,
                                flex: 1,
                                backgroundColor: STEP.PAYMENT <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>

                            <div style={{
                                height: STEP.PAYMENT <= currentStep ? 26 : 14,
                                width: STEP.PAYMENT <= currentStep ? 26 : 14,
                                borderRadius: 20,
                                backgroundColor: STEP.PAYMENT <= currentStep ? Palette.COLOR_PRIMARY : 'grey'
                            }}/>

                            <div style={{height: 1, flex: 1, backgroundColor: 'grey', opacity: 0}}/>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 10}}>
                        <Col style={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingRight: 0,
                            fontWeight: '600',
                            color: Palette.COLOR_PRIMARY
                        }}>
                            Recipient
                        </Col>

                        <Col style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            display: "flex",
                            justifyContent: 'center',
                            fontWeight: STEP.AMOUNT <= currentStep ? '600' : '400',
                            color: STEP.AMOUNT <= currentStep ? Palette.COLOR_PRIMARY : 'grey',
                            fontSize: STEP.AMOUNT <= currentStep ? '1em' : '.9em'
                        }}>
                            Amount
                        </Col>

                        <Col style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            display: "flex",
                            justifyContent: 'center',
                            fontWeight: STEP.CONFIRMATION <= currentStep ? '600' : '400',
                            color: STEP.CONFIRMATION <= currentStep ? Palette.COLOR_PRIMARY : 'grey',
                            fontSize: STEP.CONFIRMATION <= currentStep ? '1em' : '.9em'
                        }}>
                            Confirmation
                        </Col>

                        <Col style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: STEP.PAYMENT <= currentStep ? '600' : '400',
                            color: STEP.PAYMENT <= currentStep ? Palette.COLOR_PRIMARY : 'grey',
                            fontSize: STEP.PAYMENT <= currentStep ? '1em' : '.9em'
                        }}>
                            Payment
                        </Col>
                    </Row>
                </Container>

                <Row style={{marginTop: 15}}>
                    <Col style={{width: '100%', height: 1, backgroundColor: '#878787'}}/>
                </Row>
            </Container>


            <RecipientSection
                defaultRecipient={props.location.state ? props.location.state.recipient : null}
                show={currentStep === STEP.RECIPIENT}
                onNext={(recipientObj, orderPurposeObj, orderDetailObj) => {
                    setRecipient(recipientObj);
                    setOrderPurpose(orderPurposeObj);
                    setOrderDetail(orderDetailObj)
                    setCurrentStep(STEP.AMOUNT)
                }} windowWidth={windowWidth}
            />

            <AmountSection show={currentStep === STEP.AMOUNT}
                           onNext={(sourceCurrency, sourceAmount, destinationCurrency) => {
                               OrderDao.createNew(
                                   {
                                       recipient_id: recipient.id,
                                       bank_name: recipient.bank_name,
                                       bank_account_number: recipient.bank_account_number,
                                       source_currency: sourceCurrency.currency_code,
                                       source_amount: sourceAmount,
                                       // rupiah_fee: selectedCurrency.fee,
                                       destination_currency: destinationCurrency.currency_code,
                                       order_purpose: orderPurpose,
                                       details: orderDetail,
                                       type: sourceCurrency.currency_code === 'IDR' && sourceCurrency.currency_code === destinationCurrency.currency_code ? 'DOMESTIC' :
                                           sourceCurrency.currency_code === 'IDR' &&  sourceCurrency.currency_code !== destinationCurrency.currency_code ? 'OUTGOING' :
                                               destinationCurrency.currency_code === 'IDR' &&  sourceCurrency.currency_code !== destinationCurrency.currency_code ? 'INCOMING' :
                                                   'FOREIGN'
                                   }
                               ).then(res => {
                                   if(res.order_number) {
                                       alert('Order has been created successfully!');
                                       setCurrentStep(STEP.CONFIRMATION)
                                   }
                               }).catch(err => console.log(err))
                           }} onBack={() => setCurrentStep(STEP.RECIPIENT)}/>

            <ConfirmationSection show={currentStep === STEP.CONFIRMATION} onNext={() => setCurrentStep(STEP.PAYMENT)}
                                 onBack={() => setCurrentStep(STEP.AMOUNT)}/>

            <PaymentSection
                id={props.location.state ? props.location.state.id : props.match.params ? props.match.params.orderId : null}
                onNext={() => {
            }} show={currentStep === STEP.PAYMENT}/>


            {/**/}
            {/**/}


        </AppContainer>
    )
}
