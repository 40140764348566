export default {
    ORDER_CREATED: 'Order created',
    ORDER_PROCESSED: 'Order is done',
    PAYMENT_APPROVED: 'Payment is approved',
    PAYMENT_REJECTED: 'Payment rejected, please reupload the receipt!',
    // RECEIVED_BY_RECIPIENT: 'Successfully received by recipient',
    TRANSACTION_FAILED: 'Transaction failed',
    WAITING_APPROVAL: 'Receipt uploaded. Waiting for admin approval',
    WAITING_PAYMENT: 'Waiting for payment',
    ORDER_REJECTED: 'Order rejected',
    RATE_UPDATED: 'Rate Updated'
}
