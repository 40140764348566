import React, {useState} from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import AppContainer from "../reusables/AppContainer";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import styles from "../styles";
import util from "../util/util";
import history from "../history";
import CustomerDao from "../daos/CustomerDao";

export default function SignUpPage(props) {
    const [username, setUsername] = useState('');
    const [accountType, setAccountType] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});


    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptRegister()
        }
    }

    const attemptRegister = () => {
        // localStorage.token = 'test';

        const errorsTemp = {};
        setErrors(errorsTemp);

        if(!util.validateEmail(email))  errorsTemp["EMAIL"] = "Please enter a valid email" ;
        if(password !== confirmPassword)  errorsTemp["CONFIRM PASSWORD"] = "Confirm password doesn't match" ;
        if(!username) errorsTemp["USERNAME"] = 'Username cannot be blank';
        if(!accountType) errorsTemp["ACCOUNT TYPE"] = 'Please select at least 1 account type';
        if(!email) errorsTemp["EMAIL"] = 'Email cannot be blank';
        if(!password) errorsTemp["PASSWORD"] = 'Password cannot be blank';
        if(!confirmPassword) errorsTemp["CONFIRM PASSWORD"] = 'Confirm password cannot be blank';
        if(Object.keys(errorsTemp).length === 0) {
            const body = {
                email,
                username,
                password
            };

            CustomerDao.isEmailAvailable(email).then(res => {
                if(!res.available) {
                    const errorsTemp = {};
                    errorsTemp["EMAIL"] = "Email has been used by another user" ;
                    setErrors(errorsTemp)
                } else {
                    if(accountType === 'Corporate') {
                        history.push('/corporate-detail', body)
                    } else if (accountType === 'Individual') {
                        history.push('/individual-detail', body)
                    } else if (accountType === 'Financial Institution') {
                        history.push('/financial-institution-detail', body)
                    }
                }
            }).catch(err => console.log(err))
        }

        setErrors(errorsTemp)
    }

    return (
        <AppContainer>
            <Container>
                <Row style={styles.header}>
                    Sign Up
                </Row>

                <DefaultTextInput onKeyPress={onKeyPress} field={'USERNAME'} errors={errors} onChange={(event) => setUsername(event.target.value)}/>

                <DefaultSelectInput field={'ACCOUNT TYPE'} options={['Corporate', 'Financial Institution', 'Individual']} errors={errors} onChange={(option) => setAccountType(option.value)} value={accountType ? {label: accountType, value: accountType} : null}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'EMAIL'} type={'email'} errors={errors} onChange={(event) => setEmail(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'PASSWORD'} type={'password'} errors={errors} onChange={(event) => setPassword(event.target.value)}/>

                <DefaultTextInput onKeyPress={onKeyPress} field={'CONFIRM PASSWORD'} type={'password'} errors={errors} onChange={(event) => setConfirmPassword(event.target.value)}/>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptRegister}>
                        Sign Up
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    );
}