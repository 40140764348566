import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Button from "react-bootstrap/Button";
import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import DefaultCheckBox from "../reusables/DefaultCheckBox";
import Col from "react-bootstrap/Col";
import Palette from "../Palette";
import history from "../history";
import util from "../util/util";
import CustomerDao from "../daos/CustomerDao";

export default function SignInPage(props) {
    const [errors, setErrors] = useState({});
    const [isChecked, setChecked] = useState(true);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isError, setError] = useState(null);

    useEffect(() => {
        if (localStorage.token) history.push('/orders')
    }, [])

    const attemptLogin = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);

        if (!util.validateEmail(email)) errorsTemp["EMAIL"] = "Please enter a valid email";
        if (!email) errorsTemp["EMAIL"] = 'Email cannot be blank';
        if (!password) errorsTemp["PASSWORD"] = 'Password cannot be blank';
        if (Object.keys(errorsTemp).length === 0) {
            const body = {
                email,
                password
            };

            CustomerDao.login(body).then(res => {
                if(isChecked) localStorage.token = res.token;
                else sessionStorage.token = res.token;

                history.push(props.location.state && props.location.state.redirect ? props.location.state.redirect  : '/orders')
            }).catch(err => {
                setError(true)
                console.log(err)
            })
        }

        setErrors(errorsTemp)
    }

    const onKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            ev.preventDefault();

            attemptLogin()
        }
    }

    return (
        <AppContainer>
            <Container>
                <Row style={styles.header}>
                    Sign In
                </Row>

                <DefaultTextInput field={'EMAIL'} type={'email'} errors={errors}
                                  onChange={(event) => setEmail(event.target.value)}
                                  onKeyPress={onKeyPress}/>

                <DefaultTextInput field={'PASSWORD'} type={'password'} errors={errors}
                                  onChange={(event) => setPassword(event.target.value)}
                                  onKeyPress={onKeyPress}/>

                <DefaultCheckBox
                    description={'Remember Me'}
                    isChecked={isChecked}
                    setChecked={setChecked}
                    right={
                        <Col style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            marginBottom: 3
                        }}>
                            <a href={'forgot-password'}
                               style={{cursor: 'pointer', color: Palette.COLOR_PRIMARY, fontWeight: '600'}}>
                                Forgot Password
                            </a>
                        </Col>
                    }/>

                {isError ?
                    <div style={{fontSize: '80%', textAlign: 'center', color: 'red', marginTop: 15, marginBottom: 15}}>
                        Failed to sign in. Please check your credential and try again.
                    </div> : null
                }

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={{...styles.button, marginBottom: 30}} onClick={attemptLogin}>
                        Sign In
                    </Button>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    Don't have an account? <a href={'/sign-up'} style={{marginLeft: 5, color: Palette.COLOR_PRIMARY, fontWeight: '600'}}> Create an account</a>
                </Row>
            </Container>
        </AppContainer>
    )
}