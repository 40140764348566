import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import React from "react";
import Select, {components} from "react-select";
import {FaAngleDown} from "react-icons/fa";
import styles from "../styles";

export default function DefaultSelectInput(props) {
    const {field, options, optionsWithValue, required, errors, onChange, value} = props;

    const error = errors ? errors[field] : null;

    const optionsArr = [{label: 'Select', value: '', disabled: 'yes'}];

    if (options) {
        options.map(
            option => {
                optionsArr.push({label: option, value: option})
            }
        )
    }

    const DropdownIndicator = props => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <FaAngleDown color={'grey'}/>
                </components.DropdownIndicator>
            )
        );
    };

    return (
        <Row style={{marginBottom: 5}}>


            <Col style={{display: 'flex', justifyContent: 'center'}}>
                <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                    {field ?
                        <Form.Label
                            style={{marginBottom: 3, fontWeight: '700', fontSize: '.9em', color: '#a0a4a7'}}>
                            {field} {required ? ' *' : null}
                        </Form.Label> : null
                    }
                    <Select
                        onChange={onChange}
                        isOptionDisabled={(option) => option.disabled === 'yes'}
                        value={value ? value : optionsArr[0]}
                        options={options ? optionsArr : optionsWithValue}
                        styles={error ? styles.selectError : styles.select}
                        components={{DropdownIndicator, IndicatorSeparator: () => null}}
                    />
                    {error ? <div style={{
                        marginTop: '.25rem',
                        fontSize: '80%',
                        width: '100%',
                        color: '#DC3545'
                    }}>{error}</div> : null}
                </Form.Group>
            </Col>
        </Row>
    )
}