import React from 'react';
import logo from './logo.svg';
import './App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Palette from "./Palette";
import Form from "react-bootstrap/Form";
import Checkbox from "@material-ui/core/Checkbox";
import {withStyles} from '@material-ui/core/styles';
import {Router, HashRouter, Route, useHistory, Switch} from "react-router-dom"
import Button from "react-bootstrap/Button";
import SignUpPage from "./pages/SignUpPage";
import CorporateDetail from "./pages/CorporateDetailPage";
import FinancialInstitutionDetailPage from "./pages/FinancialInstitutionDetailPage";
import IndividualDetailPage from "./pages/IndividualDetailPage";
import SignInPage from "./pages/SignInPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import PageNotFound from "./pages/PageNotFound";
import OrderPage from "./pages/OrderPage";
import CreateOrderPage from "./pages/CreateOrderPage";
import OrderCompletedPage from "./pages/OrderCompletedPage";
import RecipientPage from "./pages/RecipientPage";
import ManageRecipientPage from "./pages/ManageRecipientPage";
import history from "./history";
import PrivateRoute from "./PrivateRoute";
import NoAuthOnlyRoute from "./NoAuthOnlyRoute";

function App() {
    return (
        <>
            <Router basename="/" history={history}>
                <Switch>
                    <NoAuthOnlyRoute exact path="/" component={SignInPage}/>
                    <NoAuthOnlyRoute exact path="/sign-up" component={SignUpPage}/>
                    <NoAuthOnlyRoute exact path="/forgot-password" component={ForgotPasswordPage}/>
                    <PrivateRoute exact path="/change-password" component={ChangePasswordPage}/>
                    <NoAuthOnlyRoute exact path="/change-password/:token" component={ChangePasswordPage}/>
                    <NoAuthOnlyRoute exact path="/corporate-detail" component={CorporateDetail}/>
                    <PrivateRoute exact path="/corporate-detail/:action" component={CorporateDetail}/>
                    <NoAuthOnlyRoute exact path="/financial-institution-detail" component={FinancialInstitutionDetailPage}/>
                    <PrivateRoute exact path="/financial-institution-detail/:action" component={FinancialInstitutionDetailPage}/>
                    <NoAuthOnlyRoute exact path="/individual-detail" component={IndividualDetailPage}/>
                    <PrivateRoute exact path="/individual-detail/:action" component={IndividualDetailPage}/>
                    <PrivateRoute exact path="/orders" component={OrderPage}/>
                    <PrivateRoute exact path="/recipients" component={RecipientPage}/>
                    <PrivateRoute exact path="/create-order" component={CreateOrderPage}/>
                    <PrivateRoute exact path="/confirm-payment/:orderId" component={CreateOrderPage}/>
                    <PrivateRoute exact path="/order-completed" component={OrderCompletedPage}/>
                    <PrivateRoute exact path="/recipient/:action" component={ManageRecipientPage}/>
                    <Route exact path={"*"} component={PageNotFound}/>
                </Switch>
            </Router>
        </>
    )
}

export default App;
