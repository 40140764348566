import Cookies from "universal-cookie";

// const BASE_URL = 'http://wellworld.id:43788/';
const BASE_URL = 'https://ar-api-2.komunestudio.com/';
const cookies = new Cookies();

export default class httpRequest {
    static set = async (endpoint, method, body) => {


        const headers = body instanceof FormData ?
            {
                Accept: 'application/json',
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : sessionStorage.token ? 'Bearer ' + sessionStorage.token : null,
            } :
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': localStorage.token ? 'Bearer ' + localStorage.token : sessionStorage.token ? 'Bearer ' + sessionStorage.token : null,
            };

        return (resolve, reject) => {
            console.log(`[${method}] ${BASE_URL}${endpoint}`);

            fetch(BASE_URL + endpoint, {
                method,
                headers,
                body
            })
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    if (data.http_status >= 400 && data.http_status <= 500) {
                        reject(data);
                    } else {
                        resolve(data);
                    }
                })
                .catch((e) => {
                    console.log('err', e)
                    reject('Network Request Failed');
                });
        }
    }
}
