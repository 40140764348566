import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styles from "../styles";
import DefaultTextInput from "../reusables/DefaultTextInput";
import DefaultSelectInput from "../reusables/DefaultSelectInput";
import Button from "react-bootstrap/Button";
import AppContainer from "../reusables/AppContainer";
import React, {useEffect, useState} from "react";
import history from "../history";
import CustomerDao from "../daos/CustomerDao";

export default function ChangePasswordPage(props) {
    const [errors, setErrors] = useState({});
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [withRecoveryToken, setWithRecoveryToken] = useState(props.match.params && props.match.params.token);

    useEffect(() => {
        if (withRecoveryToken) {
            CustomerDao.checkRecoveryToken({
                token: props.match.params.token
            }).then(res => {
                if (res.success) console.log(res)
                else history.push('/')
            }).catch(err => {
                if (err.http_status === 404) history.push('/');
                else history.push('/')
            })
        }
    }, [])

    const attemptSubmit = () => {
        const errorsTemp = {};
        setErrors(errorsTemp);

        if (newPassword !== confirmPassword) errorsTemp["RETYPE NEW PASSWORD"] = "Confirm password doesn't match";
        if (!oldPassword && !withRecoveryToken) errorsTemp["OLD PASSWORD"] = 'Old password cannot be blank';
        if (!newPassword) errorsTemp["NEW PASSWORD"] = 'New password cannot be blank';
        if (!confirmPassword) errorsTemp["RETYPE NEW PASSWORD"] = 'New password confirmation cannot be blank';
        if (Object.keys(errorsTemp).length === 0) {
            let body = {
                old_password: oldPassword,
                new_password: newPassword,
            };

            if (withRecoveryToken) {
                body = {
                    token: props.match.params.token,
                    new_password: newPassword
                }
            }

            if (!withRecoveryToken) {
                CustomerDao.changePassword(body).then(res => {
                    if (res.success) {
                        alert('Your password has been updated successfully')
                        history.push('/')
                    }
                    else {
                        alert('An error occurred')
                    }
                }).catch(err => {
                    console.log(err)
                    if (err.http_status && err.http_status === 400) {
                        const errorsTemp = {};
                        errorsTemp["OLD PASSWORD"] = "Wrong password";
                        setErrors(errorsTemp);
                    } else {
                        alert('An error occurred')
                    }
                });
            } else {
                CustomerDao.changePasswordWithRecoveryToken(body).then(res => {
                    if (res.success) {
                        alert('Your password has been updated successfully')

                        history.push('/')
                    } else {

                    }
                }).catch(err => {
                    alert('An error occurred');
                    console.log(err)
                })
            }
        } else setErrors(errorsTemp)
    }

    return (
        <AppContainer>
            <Container>
                <Row style={styles.header}>
                    Change Password
                </Row>

                <Row style={styles.subHeader}>
                    Enter a new password for your account
                </Row>

                {withRecoveryToken ? null :
                    <DefaultTextInput field={'OLD PASSWORD'} type={'password'} errors={errors}
                                      onChange={event => setOldPassword(event.target.value)}/>
                }

                <DefaultTextInput field={'NEW PASSWORD'} type={'password'} errors={errors}
                                  onChange={event => setNewPassword(event.target.value)}/>

                <DefaultTextInput field={'RETYPE NEW PASSWORD'} type={'password'} errors={errors}
                                  onChange={event => setConfirmPassword(event.target.value)}/>

                <Row style={{marginBottom: 5, marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                    <Button style={styles.button} onClick={attemptSubmit}>
                        Change
                    </Button>
                </Row>
            </Container>
        </AppContainer>
    )
}