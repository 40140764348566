import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import React from "react";
import moment from "moment";

export default function DefaultTextInput(props) {
    const {field, type, required, disabled, value, errors, onChange, onKeyPress, style, placeholder} = props;
    const error = errors[field];

    return (
        <Row style={{marginBottom: 5, ...style}}>
            <Col style={{display: 'flex', justifyContent: 'center'}}>
                <Form.Group style={{width: 'inherit', maxWidth: 580}}>
                    {field ?
                        <Form.Label
                            style={{
                                marginBottom: 3,
                                fontWeight: '700',
                                fontSize: '.9em',
                                color: '#a0a4a7'
                            }}>
                            {field} {required ? ' *' : null}
                        </Form.Label> : null
                    }
                    <Form.Control
                        isInvalid={!!error}
                        value={value}
                        disabled={disabled}
                        type={type ? type : 'text'}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        placeholder={placeholder}
                        max={moment().format('YYYY-MM-DD')}
                    />

                    {error ?
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback> : null
                    }
                </Form.Group>
            </Col>
        </Row>
    )
}