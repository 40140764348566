import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Palette from "../Palette";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import styles from "../styles";
import AppContainer from "./AppContainer";
import React, {useEffect, useState} from "react";
import {GoPlus} from "react-icons/go";
import DefaultSelectInput from "./DefaultSelectInput";
import RecipientDao from "../daos/RecipientDao";
import Col from "react-bootstrap/Col";

export default function RecipientSection(props) {
    const {onNext, windowWidth, show, defaultRecipient} = props;
    const [recipientMapper, setRecipientMapper] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [recipient, setRecipient] = useState({});
    const [orderPurpose, setOrderPurpose] = useState(null);
    const [orderDetail, setOrderDetail] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    useEffect(() => {
        if (show) {
            if(defaultRecipient) setRecipient(defaultRecipient.id)

            console.log(props)

            const recipientMapperTemp = [];
            const recipientsTemp = [];

            RecipientDao.getAll().then(res => {
                res.map(recipient => {
                    recipientMapperTemp[recipient.id] = recipient;
                    recipientsTemp.push({value: recipient.id, label: recipient.name})
                })

                setRecipientMapper(recipientMapperTemp)
                setRecipients(recipientsTemp)
            }).catch(err => console.log(err))
        }
    }, [show])

    const onSubmit = () => {
        if (!recipientMapper[recipient]) setErrorMsg('Please select at least 1 recipient')
        else if (!orderPurpose) setErrorMsg('Please select at least 1 order purpose')
        else if (!orderDetail) setErrorMsg('Order detail cannot be blank')
        else onNext(recipientMapper[recipient], orderPurpose, orderDetail);
    }

    return (
        <Container fluid style={{display: show ? 'block' : 'none'}}>
            <Container style={{display: 'flex', justifyContent: 'center', paddingBottom: 100, marginTop: 60}}>
                <Row style={{
                    backgroundColor: '#f5f5f5',
                    boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.3)',
                    width: '100%',
                    maxWidth: 600,
                    borderRadius: 15,
                    marginTop: 60,
                    paddingLeft: 50,
                    paddingRight: 50,
                    paddingTop: 40,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingBottom: 50,
                }}>
                    <Row
                        style={{
                            color: Palette.COLOR_PRIMARY,
                            fontSize: '1.2em',
                            fontWeight: '500',
                            width: '100%',
                            paddingLeft: 15
                        }}>
                        Select Recipient
                    </Row>

                    <Row style={{width: '100%', marginTop: 20, paddingLeft: 15, paddingRight: 15}}>

                        <Form.Group style={{width: '100%'}}>
                            <DefaultSelectInput optionsWithValue={recipients}
                                                value={recipientMapper[recipient] ? {value: recipient, label: recipientMapper[recipient].name} : defaultRecipient ? {value: defaultRecipient.id, label: defaultRecipient.name} : null}
                                                onChange={(option) => setRecipient(option.value)}/>
                        </Form.Group>
                    </Row>

                    <Row style={{marginLeft: 0, paddingLeft: 15}}>
                        or
                    </Row>

                    <Row
                        style={{
                            color: Palette.COLOR_PRIMARY,
                            fontSize: '1.2em',
                            fontWeight: '500',
                            width: '100%',
                            marginTop: 30,
                            paddingLeft: 15
                        }}>
                        Recipient Not Registered?
                    </Row>

                    <Row style={{marginTop: 25, paddingLeft: 15}}>
                        <a href={'/recipient/add?redirect=orders'}>
                            <Button style={{...styles.button, width: 230, marginBottom: 40}}>
                                <GoPlus size={25} style={{marginRight: 15}}/>

                                Add new recipient
                            </Button>
                        </a>
                    </Row>

                    <Row style={{backgroundColor: 'grey', height: 2}}/>

                    <Row
                        style={{
                            color: Palette.COLOR_PRIMARY,
                            fontSize: '1.2em',
                            fontWeight: '500',
                            width: '100%',
                            marginTop: 30,
                            paddingLeft: 15
                        }}>
                        Select Order Purpose
                    </Row>

                    <Row style={{width: '100%', marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15}}>
                        <Form.Group style={{width: '100%'}}>
                            <DefaultSelectInput
                                options={["Online Shopping", "Business"]}
                                value={orderPurpose ? {label: orderPurpose, value: orderPurpose} : null}
                                onChange={(option) => setOrderPurpose(option.value)}
                            />
                            {/*    <Form.Control as="select" style={{borderColor: '#e6e6e6'}}>*/}
                            {/*        <option value="" disabled selected>Recipient Name</option>*/}
                            {/*        <option value="hurr">John Doe</option>*/}
                            {/*    </Form.Control>*/}
                        </Form.Group>
                    </Row>

                    <Row style={{backgroundColor: 'grey', height: 2}}/>

                    <Row
                        style={{
                            color: Palette.COLOR_PRIMARY,
                            fontSize: '1.2em',
                            fontWeight: '500',
                            width: '100%',
                            marginTop: 30,
                            paddingLeft: 15
                        }}>
                        Fill In Order Details
                    </Row>

                    <Row style={{paddingLeft: 15, paddingRight: 15}}>
                        <Form.Control as="textarea" rows="8"
                                      placeholder={'Type order details here...'}
                                      onChange={(event) => setOrderDetail(event.target.value)}
                                      style={{
                                          borderRadius: 0,
                                          resize: 'none',
                                          paddingLeft: 20,
                                          paddingTop: 15,
                                          marginTop: 15
                                      }}/>
                    </Row>


                    <Row style={{marginTop: 25, paddingLeft: 15, display: 'flex', justifyContent: 'flex-end'}}>
                        <Col sm={12} md={6} style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            color: 'red',
                            marginBottom: windowWidth < 768 ? 20 : 0
                        }}>
                            {errorMsg}
                        </Col>

                        <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button style={{...styles.button, marginBottom: 10}} onClick={onSubmit}>
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </Container>
    )
}